import {bindable, customElement} from "aurelia-framework";
import ResizeObserver from 'resize-observer-polyfill';

@customElement('raago-fixed-bar')
export class RaagoFixedBar {
  static barListTop = [];

  @bindable() bottom = false;

  shadowElement;
  contentElement;

  resizeObserver;

  attached() {
    if (!this.bottom) {
      RaagoFixedBar.barListTop.push(this);
    }

    this.resizeObserver = new ResizeObserver(entries => {
      // We wrap it in requestAnimationFrame to avoid this error - "ResizeObserver loop limit exceeded"
      // This error means that ResizeObserver was not able to deliver all observations within a single animation frame.
      // By wrapping it in requestAnimationFrame you can limit the executions to a single frame.
      // https://stackoverflow.com/a/58701523
      window.requestAnimationFrame(() => {
        // Ignore error where DOM elements are not set
        // If the DOM Elements are not set we cannot adjust them.
        // This shouldn't happen because this lambda should run after they are attached.
        // But we have observed some null pointer errors in production that we can't reproduce locally.
        // Possibly the DOM elements are unbound before detached() has a chance to unregister this ResizeObserver.
        if (this.shadowElement === null && this.contentElement === null) {
          return;
        }

        // The resize event happened to the contentElement.
        let contentElement = entries[0].target;

        // If shadow element is not same height as content, change shadow element height.
        // Should not be processed for bottom fixed bars (because it's not implemented).
        if (!this.isBottom && this.shadowElement.style.height !== contentElement.offsetHeight + 'px') {
          this.shadowElement.style.height = contentElement.offsetHeight + 'px';

          // If this fixed bar's height changed the position of the next element needs to be adjusted.
          this.updateSiblingsPosition();
        }
      });
    });
    this.resizeObserver.observe(this.contentElement);
  }

  detached() {
    this.resizeObserver.unobserve(this.contentElement);
    this.resizeObserver = null;
    console.debug("Before barList remove: ");
    console.debug(RaagoFixedBar.barListTop);
    RaagoFixedBar.barListTop = RaagoFixedBar.barListTop.filter(bar => bar !== this);
    console.debug("After barList remove: ");
    console.debug(RaagoFixedBar.barListTop);
    RaagoFixedBar.barListTop[0].setTopPx(0);
  }

  updateSiblingsPosition() {
    // What is my index?
    let myIndex = RaagoFixedBar.barListTop.indexOf(this);

    // Am I the last one?
    if (myIndex >= RaagoFixedBar.barListTop.length - 1) {
      return;
    }

    // Who's next?
    let nextBar = (RaagoFixedBar.barListTop)[myIndex + 1];

    // Propagate
    nextBar.setTopPx(this.contentElement.offsetTop + this.contentElement.offsetHeight);
  }

  setTopPx(px) {
    console.debug("Setting top to: " + px + ". barList.length: " + RaagoFixedBar.barListTop.length);
    this.contentElement.style.top = px + 'px';
    this.updateSiblingsPosition();
  }

  get isBottom() {
    return this.bottom === true || this.bottom === 'true';
  }

  toString() {
    return this.contentElement.innerHTML;
  }
}

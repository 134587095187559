import {inject} from 'aurelia-framework';
import Backend from "../backend";
import isMobile from "ismobilejs";
import {UserInfoProvider} from "../util/user-info-provider";

@inject(Backend, UserInfoProvider)
export class Home {
  showGrid = false
  backend;
  userInfoProvider;

  iconKey = 'bread';

  constructor(backend, userInfoProvider) {
    this.backend = backend;
    this.userInfoProvider = userInfoProvider;
    this.isMobile = isMobile().phone;
  }

  activate(params) {
    return Promise.all([
      this.backend.CrateCatalogQueryHandler_handle()
        .then(result => this.products = result.items),
      this.backend.CmsFragmentPublicQueryHandler_handle({items: [
            {cmsFragmentKey: "home__section1_image", imgWidth: 1920}, // used for mobile only. See usage in markup
            {cmsFragmentKey: "home__section1_video"},
            {cmsFragmentKey: "home__section1_html"},
            {cmsFragmentKey: "home__section2_html"},
            {cmsFragmentKey: "home__section3_1_html"},
            {cmsFragmentKey: "home__section3_2_html"},
            {cmsFragmentKey: "home__section3_3_html"},
            {cmsFragmentKey: "home__section3_1_image"},
            {cmsFragmentKey: "home__section3_2_image"},
            {cmsFragmentKey: "home__section3_3_image"},
            {cmsFragmentKey: "home__section4_html"},
            {cmsFragmentKey: "home__section5_html"},
            {cmsFragmentKey: "home__section5_image", imgWidth: 750},
            {cmsFragmentKey: "home__section6_html"},
            {cmsFragmentKey: "home__section6_image", imgWidth: 750},
            {cmsFragmentKey: "home__section7_html"},
            {cmsFragmentKey: "home__section8_image", imgWidth: 750},
            {cmsFragmentKey: "home__section8_html"},
            {cmsFragmentKey: "home__section9_html"},
          ]})
        .then(result => {
          this.cmsFragments = result;
        }),
      this.backend.NewsItemQueryHandler_handle({limit: 1})
        .then(result => this.newsItem = result.items[0]),
    ]);
  }

  bind() {
    if (this.isMobile) {
      // Delay loading on advice from Bonzers SEO experts:
      // "For mobile, consider delaying load of the embedsocial section where
      // you can show instagram videos - this could be delayed by using an
      // eventlistener that triggers when the user interacts with the screen
      // (touchstart, scroll, click, mousemove)"
      document.addEventListener("touchstart", () => this.initEmbedSocial());
      document.addEventListener("scroll", () => this.initEmbedSocial());
      document.addEventListener("click", () => this.initEmbedSocial());
      document.addEventListener("mousemove", () => this.initEmbedSocial());
    } else {
      this.initEmbedSocial();
    }
  }

  initEmbedSocial() {
    // If exists do not create again
    if (document.getElementById('EmbedSocialHashtagScript')) {
      return;
    }
    // Create new script element
    var scriptElement = document.createElement("script");
    scriptElement.id = 'EmbedSocialHashtagScript';
    scriptElement.src = "https://embedsocial.com/cdn/ht.js";
    // Will normally just get "Script error."
    // https://sentry.io/answers/script-error/
    // This tells the browser that the target file should be fetched “anonymously”. This means that no potentially user-identifying information like cookies or HTTP credentials will be transmitted by the browser to the server when requesting this file.
    scriptElement.crossOrigin = 'anonymous'

    // Add script element to html head
    let headElement = document.getElementsByTagName("head")[0];
    headElement.appendChild(scriptElement);

    // Remove event listeners. This was a one time action.
    if (this.isMobile) {
      document.removeEventListener("touchstart", this.initEmbedSocial);
      document.removeEventListener("scroll", this.initEmbedSocial);
      document.removeEventListener("click", this.initEmbedSocial);
      document.removeEventListener("mousemove", this.initEmbedSocial);
    }
  }
}

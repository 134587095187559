import {bindable, customElement, inject, observable} from 'aurelia-framework';
import Backend from "../backend";
import {RMatomo} from "../util/r-matomo";
import {I18N} from "aurelia-i18n";

@customElement('featured-products-slider-component')
@inject(Element, Backend, I18N)
export class FeaturedProductsSliderComponent {
  element;
  backend;
  i18N;
  scrollContainerRef;

  @bindable() subscriptionId;
  @bindable() disableAutoCheckout; // Set to true to avoid this component postinc checkout command to backend. Event will still be raised and caller can perform checkout itself.

  featuredProducts;

  @observable({changeHandler: 'selectedCategoryIdChanged'}) selectedCategoryId;

  constructor(element, backend, i18N) {
    this.element = element;
    this.backend = backend;
    this.i18N = i18N;
  }

  attached() {
    this.backend.SubProductCategorizedQueryHandler_handle({})
      .then(result => {
        this.categories = result.categories;
        this.products = result.products;

        // Featured products: add category if any products are featured.
        let featuredProducts = this.products.filter(product => product.isFeaturedOnSubProducts);
        if (featuredProducts.length > 0) {
          this.categories.unshift({
            productCategoryId: null,
            name: this.i18N.tr('domain.product.featuredCategoryName'),
            iconKey: 'heart',
            isSyntheticCategoryForFeaturedProducts: true
          })
        }
      })
      .catch(error => {
        // Mute because my-page can work fine without this component

        // Log to backend
        this.backend.RecordFrontendLogCommandHandler_handle({logData: 'error while loading subproducts in featured-products-slider-component: ' + JSON.stringify(error)})
      });
  }

  selectedCategoryIdChanged() {
    // Note: this also fixes the initial sort because it is called when the dropdown is attached.

    if (this.selectedCategoryId == null) {
      // Category == null => Show featured products
      this.featuredProducts = this.products
        .filter(product => product.isFeaturedOnSubProducts)
        .sort((p1, p2) => p1.featuredProductsSort - p2.featuredProductsSort);
    } else {
      // Show products from selected category
      this.featuredProducts = this.products
        .filter(product => product.productCategoryId === this.selectedCategoryId);
    }
  }

  buy(product) {
    this.checkoutSubProduct(product)
      .then(() => RMatomo.trackGoalConversion(RMatomo.GOAL_ID__FEATURED_PRODUCT_CHECKOUT))
      .then(() => this.raiseEvent(product))
  }

  checkoutSubProduct(product) {
    if (this.disableAutoCheckout === true) {
      return Promise.resolve();
    } else {
      return this.backend.CheckoutSubProductCommandHandler_handle({
        subscriptionId: this.subscriptionId,
        items: [{
          productId: product.productId,
          incrementQuantity: 1,
          initialOneTimeValue: true
        }]
      });
    }
  }

  raiseEvent(product) {
    this.element.dispatchEvent(new CustomEvent('product-checked-out', {
      detail: {
        productId: product.productId,
        product: product
      },
      bubbles: true
    }));
  }

  scrollLeft() {
    this.slide('left');
  }

  scrollRight() {
    this.slide('right');
  }

  slide(direction, scrollStep = 100, scrollTotal = 256, intervalMs = 50) {
    let scrollCompleted = 0;
    let slideVar;
    slideVar = setInterval(() => {
      if (direction === 'left') {
        this.scrollContainerRef.scrollLeft -= scrollStep;
      } else {
        this.scrollContainerRef.scrollLeft += scrollStep;
      }
      scrollCompleted += scrollStep;
      if (scrollCompleted >= scrollTotal) {
        window.clearInterval(slideVar);
      }
    }, intervalMs);
  }
}

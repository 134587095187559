import Backend from 'backend';
import {inject} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {ValidationController, ValidationRules} from "aurelia-validation";
import {I18N} from "aurelia-i18n";

@inject(Backend, Router, ValidationController, I18N)
export class GiftCardPurchaseScreen {
  backend;
  router;
  validationController;
  i18n;

  subscriptionId;

  amount;
  selectedPaymentMeansId;

  constructor(backend, router, validationController, i18n) {
    this.validationController = validationController;
    this.i18n = i18n;
    this.backend = backend;
    this.router = router;
  }

  bind() {
    ValidationRules
      .ensure('amount')
        .displayName(this.i18n.tr('domain.giftCard.amount'))
        .required()
        .satisfies(v => v === undefined || Number.isInteger(Number(v)))
          .withMessage(this.i18n.tr('domain.giftCard.amount') + ' må være et heltall.')
      .on(this);
  }

  submit() {
    return this.validationController.validate()
    .then(result => {
      if (!result.valid) {
        return;
      }
      return this.backend.GiftCardPurchaseCommandHandler_handle({
          amount: this.amount,
          paymentMeansId: this.selectedPaymentMeansId,
          message: this.message
        })
        .then(giftCardId =>
          // Get the details of the newly purchased gift card. Especially info needed for url encoded info
          this.backend.MyPageQueryHandler_handle({})
            .then(result => {
              // Find the gift card just purchased
              this.giftCardPurchased = result.giftCardsPurchased.filter(giftCard => giftCard.giftCardId === giftCardId)[0];
              // Create base64 encoding for print url
              GiftCardPurchaseScreen.encodeGiftCardForUrl(this.giftCardPurchased);
            }));
    });
  }

  static encodeGiftCardForUrl(giftCard) {
    giftCard.giftCardEncoded = GiftCardPurchaseScreen.encodeGiftCardForUrl2(giftCard);
  }

  static encodeGiftCardForUrl2(giftCard) {
    let data = giftCard.token + "|" +
      giftCard.initialAmount + "|" +
      giftCard.expiryDate + "|" +
      (giftCard.message ? giftCard.message : '');
    let base64 = btoa(data);
    let urlEncoded = encodeURIComponent(base64);
    return urlEncoded;
  }

  static decodeGiftCardFromUrl(giftCardUrlEncodedBase64) {
    let base64 = decodeURIComponent(giftCardUrlEncodedBase64);
    let decoded = atob(base64);
    let split = decoded.split('|');
    return {
      token: split[0],
      initialAmount: Number(split[1]),
      expiryDate: split[2],
      message: split[3]
    }
  }
}

import Backend from 'backend';
import {inject, TaskQueue} from 'aurelia-framework';
import {IdentityProvider} from "devtag-aurelia-auth-plugin";
import {Configurations} from "devtag-aurelia-config-plugin";
import Materialize from 'materialize-css'
import {UserInfoProvider} from "../util/user-info-provider";
import {GiftCardPurchaseScreen} from "./gift-card-purchase-screen";

@inject(Backend, IdentityProvider, Configurations, TaskQueue, UserInfoProvider)
export class MyPageScreen {
  backend;
  taskQueue;

  pauseWarningModal;
  subscriptions = [];
  orders = [];
  userInfoProvider;

  constructor(backend, identityProvider, configurations, taskQueue, userInfoProvider) {
    this.backend = backend;
    this.taskQueue = taskQueue;
    this.userInfoProvider = userInfoProvider;

    // Create password reset url
    let userId = identityProvider.getUserId();
    let encodedNextUrl = encodeURIComponent(configurations.getConfig('authAppBaseUrl') + "/#/my-page");
    this.changePasswordUrl = configurations.getConfig('authAuthorityUrl') + "changepassword?next=" + encodedNextUrl + '&userId=' + userId;
  }

  activate() {
    return this.refresh();
  }

  attached() {
    this.initOrRefreshSubscriptionTabs();
  }

  initOrRefreshSubscriptionTabs() {
    if (this.subscriptions.length > 0) {
      let element = document.getElementById('tabs-row-ul');
      if (!element) {
        throw new Error('Could not find element with id tabs-row-ul')
      }
      this.tabsInstance = Materialize.Tabs.init(element, {
        onShow: element => {
          // Store what the choosen tab is
          this.tabId = element.getAttribute('id');
          // this.updateUrl();
        }
      });
      this.taskQueue.queueMicroTask({
        call: () => {
          // Open the tab we're supposed to be on. In case we are being refreshed we don't want to open the default tab.
          this.tabsInstance.select(this.tabId)

          // Recalculate tab indicator position.
          return this.tabsInstance.updateTabIndicator();
        }
      });
    }
  }

  refresh() {
    let info = this.userInfoProvider.getInfo();
    if (info.hasCustomer) {
      return Promise.all([
        this.refreshMyPageQuery(),
        this.backend.MyOrdersQueryHandler_handle()
          .then(result => this.orders = result.orders),
      ]);
    }
  }

  featuredProductCheckedOut() {
    return this.refreshMyPageQuery()
      .then(() => {
        // Reinitialize the tabs since the dom elements have changed.
        // Do it after new data has been rendered, so the dom is ready.
        this.taskQueue.queueMicroTask({
          call: () => this.initOrRefreshSubscriptionTabs()
        });
      });
  }

  refreshMyPageQuery() {
    return this.backend.MyPageQueryHandler_handle({})
          .then(result => {
            this.subscriptions = result.subscriptions;
            if (this.subscriptions.length > 0) {
              this.activeSubscription = this.subscriptions[0];
            }
            this.totalAvailableGiftCardAmount = result.totalAvailableGiftCardAmount;
            this.giftCardsPurchased = result.giftCardsPurchased;
            this.giftCardsPurchased.forEach(giftCard => GiftCardPurchaseScreen.encodeGiftCardForUrl(giftCard))
          });
  }

  setPause(subscription, order, pauseYesNo) {
    if (!subscription.weekDay) {
      this.pauseWarningModal.open();
      return Promise.resolve();
    }
    return this.backend.SetPauseCommandHandler_handle(subscription.subscriptionId, order.yearWeek, pauseYesNo)
                       .then(result => this.refresh());
  }

  chooseSubscription(subscription) {
    this.activeSubscription = subscription;
  }
}

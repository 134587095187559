import {inject} from 'aurelia-framework';
import {Router} from "aurelia-router";

@inject(Router)
export class NavBar {
  router;


  constructor(router) {
    this.router = router;
  }
}

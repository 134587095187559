export class RMatomo {
  static GOAL_ID__CHECKOUT = 1;
  static GOAL_ID__MY_PAGE = 2;
  static GOAL_ID__WEEK_EXTRA = 3;
  static GOAL_ID__CUSTOMIZABLE_CONTENT = 4;
  static GOAL_ID__CHANGE_CRATE_TYPE = 5;
  static GOAL_ID__FEATURED_PRODUCT_CHECKOUT = 6;

  static startTracking(trackerUrl, siteId, eventAggregator) {
    // Async: Add matomo script to DOM
    (function() {
      RMatomo._configurePAQ(trackerUrl, siteId);
      RMatomo._attachMatomoScript(trackerUrl);
    })();

    // Subscribe to router
    eventAggregator.subscribe('router:navigation:success', event => {
      let url = window.location.toString();

      // Do not auto track?
      if (RMatomo.isUrlDoNotAutoTrack(url)) {
        return;
      }

      // Send page views to matomo
      RMatomo.trackPageView(url, event.instruction.config.title);
    });
  }

  static trackPageView(url, title) {
    // Must get new reference. Old array does not seem to be watched by matomo.js anymore. Maybe they replaced it with a new array when processing it.
    const paq = window._paq || [];

    // debugger
    paq.push(['setCustomUrl', RMatomo._anonymizeUUIDs(url)]);
    paq.push(['setDocumentTitle', title]);

    // Remove old custom variables
    paq.push(['deleteCustomVariables', 'page']);

    // Track page view
    paq.push(['trackPageView']);
  }

  static trackGoalConversion(goalId) {
    // Must get new reference. Old array does not seem to be watched by matomo.js anymore. Maybe they replaced it with a new array when processing it.
    const paq = window._paq || [];

    paq.push(['trackGoal', goalId]);
  }

  static _configurePAQ(trackerUrl, siteId) {
    const paq = window._paq = window._paq || [];
    paq.push(['setTrackerUrl', trackerUrl + 'matomo.php']);
    paq.push(['setSiteId', siteId]);

    // Enable respect for the browsers do-not-track feature
    // Not sure why this needs to be enabled. From docs:
    // Note: Tracking requests and cookies will still be created even for people who
    //       don’t want to be tracked. To prevent any requests and cookies when people don’t
    //       want to be tracked, add the following function in the JavaScript Tracking code:
    //       _paq.push(["setDoNotTrack", true]);
    paq.push(['setDoNotTrack', true]);
  }

  static _attachMatomoScript(trackerUrl) {
    // Create script element
    let scriptElement = document.createElement('script');
    scriptElement.async = true;
    scriptElement.src = trackerUrl + '/matomo.js';
    // Enable proper error messages from different origin js file.
    // Will normally just get "Script error."
    // https://sentry.io/answers/script-error/
    // This tells the browser that the target file should be fetched “anonymously”. This means that no potentially user-identifying information like cookies or HTTP credentials will be transmitted by the browser to the server when requesting this file.
    // todo: disabled because we got cors errors.
    // scriptElement.crossOrigin = 'anonymous'

    // Attach script to dom before first script
    var firstScript = document.getElementsByTagName('script')[0];
    firstScript.parentNode.insertBefore(scriptElement, firstScript);
  }

  static whiteList = [];

  static _isWhiteListed(url) {
    let matches = this.whiteList.filter(white => url.includes(white));
    return matches.length > 0;
  }

  static addWhiteListItem(fragment) {
    this.whiteList.push(fragment);
  }

  static _anonymizeUUIDs(url) {
    if (url == null) {
      return null;
    }
    const regEx_UUID = /\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b/ig;
    let containsUUID = regEx_UUID.test(url);
    if (containsUUID && !RMatomo._isWhiteListed(url)) {
      let locationWithAnonymizedUUIDs = url.replaceAll(regEx_UUID, 'anonymized-uuid');
      return locationWithAnonymizedUUIDs;
    } else {
      return url;
    }
  }

  static doNotAutoTrackUrlList = [];

  static isUrlDoNotAutoTrack(url) {
    let matches = this.doNotAutoTrackUrlList.filter(doNotTrackUrl => url.includes(doNotTrackUrl));
    return matches.length > 0;
  }

  static doNotAutoTrackUrlWithFragment(fragment) {
    this.doNotAutoTrackUrlList.push(fragment);
  }

  static isOptOut() {
    return new Promise(resolve => {
      _paq.push([function () {
        let isUserOptedOut = this.isUserOptedOut();
        resolve(isUserOptedOut);
      }]);
    });
  }

  static optOut() {
    _paq.push(['optUserOut']);
  }

  static forgetOptOut() {
    _paq.push(['forgetUserOptOut']);
  }
}

import {inject} from 'aurelia-framework';
import Backend from "../backend";
import {activationStrategy, Router} from 'aurelia-router';

@inject(Backend, Router)
export class NewsItemIndexScreen {
  backend;

  search;
  router;

  constructor(backend, router) {
    this.backend = backend;
    this.router = router;
  }

  activate(params) {
    this.search = params.search
    return Promise.all([
      this.refresh(params.search),
      this.backend.CmsFragmentPublicQueryHandler_handle({items: [
            {cmsFragmentKey: "news_item__section1"},
          ]})
        .then(result => {
          this.cmsFragments = result;
        }),
    ]);
  }

  submitSearch() {
    this.router.navigateToRoute("news-item-index", {
      search: this.search
    });
    return this.refresh(this.search)
  }

  refresh(search) {
    return this.backend.NewsItemQueryHandler_handle({search: search, limit: 100})
      .then(result => this.newsItems = result.items);
  }

  determineActivationStrategy() {
    return activationStrategy.replace;
  }
}

import Backend from 'backend';
import {inject} from 'aurelia-framework';
import {activationStrategy, Router} from 'aurelia-router';
import {ValidationController, ValidationRules} from "aurelia-validation";
import {I18N} from "aurelia-i18n";

@inject(Backend, Router, ValidationController, I18N)
export class GiftCardPurchaseWithoutPaymentMeansScreen {
  backend;
  router;
  validationController;
  i18n;

  command = {};

  constructor(backend, router, validationController, i18n) {
    this.validationController = validationController;
    this.i18n = i18n;
    this.backend = backend;
    this.router = router;
  }

  activate(params) {
    this.giftCardPurchased = params.giftCardPurchased;
  }

  bind() {
    ValidationRules
      .ensure('amount')
        .displayName(this.i18n.tr('domain.giftCard.amount'))
        .required()
        .satisfies(v => v === undefined || Number.isInteger(Number(v)))
          .withMessage(this.i18n.tr('domain.giftCard.amount') + ' må være et heltall.')
      .ensure('firstName')
        .displayName(this.i18n.tr('domain.customer.firstName'))
        .required()
      .ensure('lastName')
        .displayName(this.i18n.tr('domain.customer.lastName'))
        .required()
      .ensure('phoneNumber')
        .displayName(this.i18n.tr('domain.customer.phoneNumber'))
        .required()
      .ensure('email')
        .displayName(this.i18n.tr('domain.customer.email'))
        .required()
      .on(this.command);
  }

  submit() {
    return this.validationController.validate()
      .then(result => {
        if (!result.valid) {
          return;
        }
        return this.backend.GiftCardPurchaseWithoutPaymentMeansCommandHandler_handle(this.command)
          .then(() => {
            this.router.navigateToRoute('gift-card-purchase-without-payment-means', {
              giftCardPurchased: true
            })
          });
      });
  }

  determineActivationStrategy() {
    return activationStrategy.replace;
  }
}

export class IntegerValueConverter {
  toView(value) {
    return value;
  }

  fromView(value) {
    // We would like to return NaN if value is not an integer, but aurelia can't handle it because NaN is not equal to anything and
    // therefore messes up the change detection.

    // Trim whitespace from strings
    if (typeof value ===  'string') {
      let trimmed = value.trim();

      // Transform empty string to null
      if (trimmed === '') {
        return null;
      }
      return trimmed;
    } else {
      return value;
    }
  }
}

import Backend from 'backend';
import {inject} from 'aurelia-framework';
import {Router} from 'aurelia-router';

@inject(Backend, Router)
export class ChangeIntervalScreen {
  backend;
  router;

  subscriptionId;
  endWeekInclusive;
  cancellationComment;

  reasons = [
    { id: 1, name: "Jeg/Vi ønsker bare en pause."},
    { id: 2, name: "Jeg/Vi rekker ikke å spise alt."},
    { id: 3, name: "Varene har ikke forventet kvalitet."},
    { id: 4, name: "Vi bytter til annen hjemlevering av mat."},
    { id: 5, name: "Regelmessige leveringer passer ikke meg/oss."},
    { id: 6, name: "Tildelt ukedag passer dårlig for oss."},
    { id: 7, name: "Vi syns utvalget er for dårlig/for lite variasjon."},
    { id: 8, name: "Vi flytter."},
    { id: 9, name: "Annet (spesifiser)"},
  ];
  selectedReason = null;


  constructor(backend, router) {
    this.backend = backend;
    this.router = router;
  }

  activate(params) {
    this.subscriptionId = params.subscriptionId;
    return Promise.all([
        this.backend.EndWeeksForDropdownQueryHandler_handle(this.subscriptionId)
          .then(result => {
            this.endWeeks = result.endWeeks;
          }),
    ]);
  }

  cancelSubscription() {
    if (!this.selectedReason) {
      this.cancellationReasonValidationMessage = 'Årsak er påkrevd';
      return Promise.resolve();
    }

    return this.backend.CancelSubscriptionCommandHandler_handle({
      subscriptionId: this.subscriptionId,
      endWeekInclusive: this.endWeekInclusive,
      cancellationReason: this.selectedReason.name,
      cancellationComment: this.cancellationComment
    })
    .then(() => this.router.navigateToRoute("my-page"));
  }
}

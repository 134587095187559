import * as dayjs from 'dayjs'

export class DateShortValueConverter {
  toView(value) {
    // null or undefined
    if (!value) {
      return value;
    }

    // If type is string, assume it might be a datetime and only try to parse the date part.
    if (typeof value === 'string' || value instanceof String) {
      value = value.substr(0, 10);
    }

    return dayjs(value).format('L');
  }
}

export class RGTag {
  static _hasConsent = false;

  static startTracking(gaTrackingId, eventAggregator) {
    // Default to empty array
    window.dataLayer = window.dataLayer || [];

    // First, set consent defaults.
    // From Google: "The default consent should be set very early, before tags read the consent state."
    RGTag._setDefaultConsentDenied();

    // Add Gtag script to DOM
    RGTag._attachGtagScript(gaTrackingId);

    // Subscribe to router
    eventAggregator.subscribe('router:navigation:success', event => {
      // Send page views to matomo
      let url = window.location.toString();
      RGTag.trackPageView(url, event.instruction.config.title);
    });
  }

  static grantConsent() {
    RGTag._hasConsent = true;
    RGTag._gtag('consent', 'update', {
      'ad_personalization': 'granted',
      'ad_storage': 'granted',
      'ad_user_data': 'granted',
      'analytics_storage': 'granted'
    });
  }

  static trackPageView(url, title) {
    if (!RGTag._hasConsent) {
      return;
    }

    window.dataLayer.push({
     'event': 'virtualPageview',
     'pageUrl': url,
     'pageTitle': title
   });
  }

  static _setDefaultConsentDenied() {
    // Deny consent by default
    RGTag._gtag('consent', 'default', {
      'ad_personalization': 'denied', // 	Sets consent for personalized advertising.
      'ad_storage': 'denied',         // 	Enables storage, such as cookies (web) or device identifiers (apps), related to advertising.
      'ad_user_data': 'denied',       // 	Sets consent for sending user data to Google for advertising purposes.
      'analytics_storage': 'denied',  // 	Enables storage, such as cookies (web) or app identifiers (apps), related to analytics, e.g. visit duration.
      //'wait_for_update': 3000,         // any positive integer 	Sets a time in milliseconds to wait for a consent update call.
      personalization_storage: "denied",
      functionality_storage: "denied",
      security_storage: "denied",
    });
  }

  static _attachGtagScript(gaTrackingId) {
    // Add gtag.js
    // Create script element
    let scriptElement = document.createElement('script');
    scriptElement.async = true;
    scriptElement.src = 'https://www.googletagmanager.com/gtag/js?id=' + gaTrackingId;

    // Attach script to dom before first script
    const firstScript = document.getElementsByTagName('script')[0];
    firstScript.parentNode.insertBefore(scriptElement, firstScript);

    // Init
    RGTag._gtag('js', new Date());
    RGTag._gtag('config', gaTrackingId);
  }

  static _gtag() {
      window.dataLayer.push(arguments);
  }
}

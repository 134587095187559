import {inject} from 'aurelia-framework';
import Backend from "../backend";

@inject(Backend)
export class HomeShort {
  showGrid = false
  backend;
  iconKey = 'bread';

  constructor(backend) {
    this.backend = backend;
  }

  activate() {
    return Promise.all([
      this.backend.CrateCatalogQueryHandler_handle()
        .then(result => this.products = result.items),
      this.backend.CmsFragmentPublicQueryHandler_handle({items: [
            {cmsFragmentKey: "home__section1_image", imgWidth: 1920},
            {cmsFragmentKey: "home__section1_html"},
            // {cmsFragmentKey: "home__section2_html"},
            {cmsFragmentKey: "home__section3_1_html"},
            {cmsFragmentKey: "home__section3_2_html"},
            {cmsFragmentKey: "home__section3_3_html"},
            {cmsFragmentKey: "home__section3_1_image"},
            {cmsFragmentKey: "home__section3_2_image"},
            {cmsFragmentKey: "home__section3_3_image"},
            {cmsFragmentKey: "home__section4_html"},
            // {cmsFragmentKey: "home__section5_html"},
            // {cmsFragmentKey: "home__section5_image", imgWidth: 750},
            // {cmsFragmentKey: "home__section6_html"},
            // {cmsFragmentKey: "home__section6_image", imgWidth: 750},
            // {cmsFragmentKey: "home__section7_html"},
            {cmsFragmentKey: "home__section8_image", imgWidth: 750},
            {cmsFragmentKey: "home__section8_html"},
            {cmsFragmentKey: "home__section9_html"},
          ]})
        .then(result => {
          this.cmsFragments = result;
        })
    ]);
  }
}
import Backend from 'backend';
import {inject} from 'aurelia-framework';
import {Router} from 'aurelia-router';

@inject(Backend, Router)
export class GiftCardClaimScreen {
  backend;
  router;

  token;

  constructor(backend, router) {
    this.backend = backend;
    this.router = router;
  }

  activate() {
    // return Promise.all([
    //     this.backend.ChangeSubscriptionCommentForPackingCommandHandler_getCommandTemplate(this.subscriptionId)
    //       .then(result => this.command = result),
    // ]);
  }

  submit() {
    return this.backend.GiftCardClaimCommandHandler_handle({
      giftCardToken: this.token
      })
      .then(giftCardId => {
        if (giftCardId !== null) {
          this.router.navigateToRoute("my-page")
          return
        }
        alert('no card claimed');
      });
  }
}

import {customElement, inject} from "aurelia-framework";
import {EventAggregator} from "aurelia-event-aggregator";
import {RouterEvent} from "aurelia-router";
import {CookieConsentService} from "./cookie-consent-service";

@customElement('cookie-consent-modal-component')

@inject(EventAggregator, CookieConsentService)
export class CookieConsentModalComponent {
  // Injected
  ea;
  cookieConsentService;

  // From view
  modal;

  // Various
  promiseResolve;
  consents = {
    advertisement: null
  };

  constructor(ea, cookieConsentService) {
    this.ea = ea;
    this.cookieConsentService = cookieConsentService;
  }

  bind() {
    // Wait until after routing complete. Otherwise, modal has not yet initialized.
    this.ea.subscribe(RouterEvent.Complete, () => {
      this.cookieConsentService.setCookieConsentModalComponent(this);
    });
  }

  unbind() {
    this.cookieConsentService.setCookieConsentModalComponent(null);
  }

  open(existingConsents) {
    this.consents = existingConsents;
    this.modal.open();
  }

  acceptAdvertisement(){
    this.consents.advertisement = true;
    this.modal.close();
  }
  rejectAdvertisement(){
    this.consents.advertisement = false;
    this.modal.close();
  }

  onCloseStart() {
    this.cookieConsentService.consentsUpdated(this.consents)
  }
}

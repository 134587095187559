import {inject} from "aurelia-framework";
import {DtToastService} from "devtag-aurelia-components-plugin";
import {RouterEvent} from "aurelia-router";
import {EventAggregator} from "aurelia-event-aggregator";

@inject(EventAggregator, DtToastService)
export class NotificationService {
  eventAggregator;

  dtToastService;
  callbacks = [];

  constructor(eventAggregator, dtToastService) {
    this.eventAggregator = eventAggregator;
    this.dtToastService = dtToastService;
    this.eventAggregator.subscribe(RouterEvent.Success, (event) => {
      this.callbacks.forEach(callback => callback(event));
      this.callbacks = [];
    });
  }

  showOnNextRouterSuccess(message) {
    this.callbacks.push(event => {
      this._show(message);
    })
  }

  showNow(message) {
    return this._show(message);
  }

  _show(message) {
    let html = `
          <div>
            <span class="r-body-text-small">${message}</span>
            <br/>
            <a href="#" onclick="
                // Prevent browser from navigating based on this link click
                event.preventDefault();
                
                // Remove this toast
                let thisToast = document.querySelector('.toast--notification');
                thisToast.remove();
              ">OK</a>
          </div>`;
    return this.dtToastService.show(html, Infinity, "toast--notification");
  }
}

import Backend from 'backend';
import {bindable, bindingMode, customElement, inject} from 'aurelia-framework';
import {Configurations} from "devtag-aurelia-config-plugin";

@customElement('existing-payment-component')
@inject(Element, Backend, Configurations)
export class ExistingPaymentComponent {
  element;
  backend;

  existingPaymentMeans;

  @bindable({defaultBindingMode: bindingMode.fromView}) selectedPaymentMeansId;

  constructor(element, backend, configurations) {
    this.element = element;
    this.backend = backend;
    this.qpEnableTestMode = configurations.getConfig('qpEnableTestMode') === true || configurations.getConfig('qpEnableTestMode') === 'true';
  }

  attached() {
    this.backend.ExistingPaymentMeansQueryHandler_handle()
      .then(result => this.existingPaymentMeans = result.paymentMeans);
  }

  chooseExistingCard(paymentMeans) {
    this.selectedPaymentMeans = paymentMeans;
    this.selectedPaymentMeansId = paymentMeans.paymentMeansId;
  }
}
import Backend from 'backend';
import {inject} from 'aurelia-framework';
import {Router} from 'aurelia-router';

@inject(Backend, Router)
export class ChangeCommentForPackingScreen {
  backend;
  router;

  subscriptionId;

  command;

  constructor(backend, router) {
    this.backend = backend;
    this.router = router;
  }


  activate(params) {
    this.subscriptionId = params.subscriptionId;
    return Promise.all([
        this.backend.ChangeSubscriptionCommentForPackingCommandHandler_getCommandTemplate(this.subscriptionId)
          .then(result => this.command = result),
    ]);
  }

  submit() {
    return this.backend.ChangeSubscriptionCommentForPackingCommandHandler_handle(this.command)
      .then(() => this.router.navigateToRoute("my-page"));
  }
}

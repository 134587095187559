import {inject} from 'aurelia-framework';
import {AuthenticationService} from "devtag-aurelia-auth-plugin";
import {Configurations} from "devtag-aurelia-config-plugin";

@inject(AuthenticationService, Configurations)
export class MyPagePublic {
  configurations;

  constructor(authenticationService, configurations) {
    this.authenticationService = authenticationService;
    this.configurations = configurations;
  }

  activate() {
    this.redirectToLogin();
  }

  authenticationService;
  redirectToLogin() {
    this.authenticationService.redirectToLogin('#/my-page', {}, {
      cancelRedirectUrl: this.configurations.getConfig('authAppBaseUrl') + "/#/"
    });
  }
}
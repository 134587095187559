import Backend from 'backend';
import {inject} from 'aurelia-framework';
import {Router} from 'aurelia-router';

import {ValidationController} from "aurelia-validation";
import {I18N} from "aurelia-i18n";

@inject(Backend, Router,
  // Same validation controller instance as the address-change-component
  ValidationController,
  I18N)
export class ChangeAddressScreen {
  backend;
  router;

  subscriptionId;

  subscriptionChangeCommand={};

  validationController;
  i18n;

  constructor(backend, router, validationController, i18n) {
    this.backend = backend;
    this.router = router;
    this.validationController = validationController;
    this.i18n = i18n;
  }


  activate(params) {
    this.subscriptionId = params.subscriptionId;
    return Promise.all([
        this.backend.ChangeSubscriptionAddressCommandHandler_getCommandTemplate(this.subscriptionId)
          .then(result => this.subscriptionChangeCommand = result),
    ]);
  }

  submit() {
    return this.validationController.validate()
      .then(result => {
        if (!result.valid) {
          return;
        }

        this.backend.UsernameAlreadyExistsQueryHandler_handle({username: this.subscriptionChangeCommand.email})
          .then(result => {
            if (result.alreadyExists) {
              this.validationController.addError(this.i18n.tr('screen.changeAddressScreen.emailAlreadyInUse'), this.subscriptionChangeCommand, 'email')
            } else {
              return this.backend.ChangeSubscriptionAddressCommandHandler_handle(this.subscriptionChangeCommand)
                .then(() => this.router.navigateToRoute("my-page"));
            }
          });
      });
  }
}

import Backend from 'backend';
import {ShoppingCart} from './shopping-cart';
import {inject, customElement, observable} from 'aurelia-framework';
import {Router} from 'aurelia-router';

@customElement('interval-component')
@inject(Element, Backend, ShoppingCart, Router)
export class IntervalComponent {
  backend;
  shoppingCart;
  router;

  // Dropdowns
  weekIntervals;
  startWeeks;

  @observable({changeHandler: 'weekIntervalChanged'})
  weekInterval;
  startWeek;
  weekDay;
  commentForPacking;
  commentForDriver;

  constructor(element, backend, shoppingCart, router) {
    this.element = element;
    this.router = router;
    this.backend = backend;
    this.shoppingCart = shoppingCart;
  }

  attached() {
    let info = this.shoppingCart.getInfo();
    this.weekInterval = info.weekInterval;
    this.crateProductName = info.crateProduct.name;
    this.startWeek = info.startWeek;
    this.weekDay = info.weekDay;
    this.commentForPacking = info.commentForPacking;
    this.commentForDriver = info.commentForDriver;

    this.backend.WeekIntervalsForDropdownQueryHandler_handle({subscriptionId: info.subscriptionId})
      .then(result => this.weekIntervals = result.weekIntervals);
    this.backend.StartWeeksForDropdownQueryHandler_handle()
      .then(result => this.startWeeks = result.startWeeks);

    this.backend.CmsFragmentPublicQueryHandler_handle({items: [
          {cmsFragmentKey: "checkout_flow__interval__section1"},
        ]})
      .then(result => {
        this.cmsFragments = result;
      })
  }

  weekIntervalChanged(newValue, oldValue) {
    if (oldValue === undefined) {
      return;
    }

    this.shoppingCart.setInterval(
      this.weekInterval,
      this.startWeek,
      this.weekDay,
      this.commentForPacking,
      this.commentForDriver);
  }

  submit() {
    // When going to next step we should also let the week extra component do it's 'next' operation.
    if (this.weekExtraComponent) {
      this.weekExtraComponent.addChosenProductsToCart();
    }
    this.element.dispatchEvent(new CustomEvent('interval-submit', {
      detail: {
        weekInterval: this.weekInterval,
        startWeek: this.startWeek,
        weekDay: this.weekDay,
        commentForPacking: this.commentForPacking,
        commentForDriver: this.commentForDriver
      },
      bubbles: true
    }));
  }

  back() {
    this.element.dispatchEvent(new CustomEvent('checkout-flow-back', {
      detail: {},
      bubbles: true
    }));
  }
}

import * as dayjs from 'dayjs'

export class DateFormatValueConverter {
  toView(value) {
    if (value) {
      // mandag 16. november
      return dayjs(value).format('dddd D[.] MMMM');

    } else {
      // moment(null) returns current date, which we don't want to be the default. Return null if we were given null.
      return value;
    }
  }
}

import {inject} from 'aurelia-framework';

import {IdentityProvider} from 'devtag-aurelia-auth-plugin';
import Backend from "../backend";
import {BindingSignaler} from "aurelia-templating-resources";

@inject(IdentityProvider, Backend, BindingSignaler)
export class UserInfoProvider {
  identityProvider;
  backend;
  bindingSignaler;

  info;

  constructor(identityProvider, backend, bindingSignaler) {
    this.identityProvider = identityProvider;
    this.backend = backend;
    this.bindingSignaler = bindingSignaler;
  }

  load() {
    return this.backend.UserInfoQueryHandler_handle({})
      .then(result => {
        this.info = result;
      })
  }

  reload() {
    return this.load()
      // Signal the nav-bar to update.
      .then(() => this.bindingSignaler.signal('update-routes-signal'));
  }

  getInfo() {
    if (!this.info) {
      throw new Error('Userinfo did not contain info. App should not have started until info was received from backend.');
    }
    return this.info;
  }
}

import {inject} from 'aurelia-framework';

import {IdentityProvider} from 'devtag-aurelia-auth-plugin';

@inject(IdentityProvider)
export class LogoutRouteHidingRouteFilterValueConverter {
  identityProvider;

  constructor(identityProvider) {
    this.identityProvider = identityProvider;
  }

  toView(routes) {
    if (this.identityProvider.isCurrentlyLoggedIn()) {
      return routes;
    } else {
      // Everything except the logout route
      return routes.filter(route => {
        return route.config.name !== 'logout';
      });
    }
  }
}

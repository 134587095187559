import Backend from 'backend';
import {inject} from 'aurelia-framework';
import {Router} from 'aurelia-router';

@inject(Backend, Router)
export class CrateCatalogScreen {
  backend;

  constructor(backend, router) {
    this.backend = backend;
    this.router = router;
  }

  attached() {
    this.refresh();
  }

  refresh() {
    return Promise.all([
      this.backend.CrateCatalogQueryHandler_handle()
        .then(result => this.products = result.items),
      this.backend.CmsFragmentPublicQueryHandler_handle({items: [
            {cmsFragmentKey: "crate_catalog__section1"},
            {cmsFragmentKey: "crate_catalog__section2"},
          ]})
        .then(result => {
          this.cmsFragments = result;
        }),
    ]);
  }
}
import {inject} from 'aurelia-framework';
import Backend from 'backend';

@inject(Backend)
export class LoggingErrorRenderer {
  backend;

  constructor(backend) {
    this.backend = backend;
  }

  render(message, details = null, stack = null, error) {
    // If error originated in backend, don't send it to backend AGAIN.
    if (error && error.name === 'ApiError') {
      return;
    }

    // Record in backend log.
    this.backend.RecordFrontendLogCommandHandler_handle({
        logData: `${message}: ${details}.`,
        stackTrace: stack,
        locationHref: window.location.href
      })
        .catch(error2 => console.error("Failed to log to backend: " + error2));
  }
}

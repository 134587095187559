export const configurationProfiles = {
  baseLineConfig: {
    baseLine: 1,
  },
  productionConfig: {
    prod: 1,
  },
  environmentVariableConfig: {
    environmentVariableConfigEnabled: 'ENVIRONMENT_VARIABLE_CONFIG_ENABLED',
    apiRoot: "API_ROOT",
    authAuthorityUrl: 'AUTH_AUTHORITY_URL',
    authAppBaseUrl: 'AUTH_APP_BASE_URL',
    qpEnableTestMode: 'QP_ENABLE_TEST_MODE',
    gaTrackingId: 'GA_TRACKING_ID',
    fbPixelId: 'FB_PIXEL_ID',
    matomoTrackerUrl: 'MATOMO_TRACKER_URL',
    matomoSiteId: 'MATOMO_SITE_ID',
  },
  developmentConfig: {
    prod: 0,
    dev: 1,
    apiRoot: "http://localhost:1934/api",
    authAuthorityUrl: 'http://localhost:1934/oidc/',
    authAppBaseUrl: 'http://localhost:8080',
    qpEnableTestMode: 'true',
    gaTrackingId: null,
    fbPixelId: null,
    matomoTrackerUrl: 'https://raago.matomo.cloud/',
    // '1' is prod, '3' is localhost testing. Empty disables tracking.
    matomoSiteId: '',
  },
};

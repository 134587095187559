import {inject, bindable, bindingMode, customElement, TaskQueue} from "aurelia-framework";

@inject(Element, TaskQueue)
@customElement('raago-quantity')
export class RaagoQuantity {
  element;
  taskQueue;

  @bindable({defaultBindingMode: bindingMode.twoWay}) quantity;
  @bindable() minQuantity = 0;
  @bindable() onlyEvent; // Do not increment bound value, only raise event about new quantity. Used if we want to synchronously refresh quantity with other data.

  constructor(element, taskQueue) {
    this.element = element;
    this.taskQueue = taskQueue;
  }

  increment() {
    this.incrementInner(1);
  }

  decrement() {
    if (this.quantity <= this.minQuantity) {
      return;
    }
    this.incrementInner(-1);
  }

  incrementInner(increment) {
    // Calculate new
    let newQuantity = this.quantity + increment;

    // Update bound quantity
    if (!this.onlyEvent) {
      this.quantity = newQuantity;
    }

    // Raise event
    this.quantityChangedEvent(newQuantity, increment);
  }

  quantityChangedEvent(quantity, increment) {
    // Enqueue because it shouldn't be processed until after the bindings has had a chance to update.
    this.taskQueue.queueMicroTask({
      call: () => {
        this.element.dispatchEvent(new CustomEvent('quantity-changed', {
          detail: {quantity: quantity, increment: increment},
          bubbles: true
        }));
      }
    });
  }
}
import Backend from 'backend';
import {inject} from 'aurelia-framework';
import {Router} from 'aurelia-router';

@inject(Backend, Router)
export class MyOrderDetailsScreen {
  backend;
  router;

  order;

  constructor(backend, router) {
    this.backend = backend;
    this.router = router;
  }

  activate(params) {
    return this.backend.MyOrderDetailsQueryHandler_handle(params.orderId)
      .then(order => this.order = order);
  }
}

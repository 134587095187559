import {RedirectToRoute} from "aurelia-router";

export function redirectToActiveSeoKey(params, routeConfig, idParamName, activeSeoKey) {
  // Redirect to ACTIVE seo url if old seo url or id is used.
  if (activeSeoKey && activeSeoKey !== params[idParamName]) {
    return new RedirectToRoute(routeConfig.name, {[idParamName]: activeSeoKey});
  }

  return true;
}

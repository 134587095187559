import {bindable, customElement} from "aurelia-framework";

@customElement('raago-spacing')
export class RaagoSpacing {
  @bindable() vertical = 'none'; // "none", "s", "m", "l"
  @bindable() horizontal = 'none'; // "none", "s", "m", "l"
  @bindable() type = 'margin'; // "margin", "padding"

  verticalClass = '';
  horizontalClass = '';

  bind() {
    if (this.type !== 'margin' && this.type !== 'padding') {
      throw new Error('Invalid argument for type. Was ['+ this.type + ']. Allowed: ["margin", "padding"].');
    }

    if (this.vertical === 'none') {
      this.verticalClass = '';
    } else if (this.vertical === 's') {
      this.verticalClass = 'r-' + this.type + '-vertical--small';
    } else if (this.vertical === 'm') {
      this.verticalClass = 'r-' + this.type + '-vertical--medium';
    } else if (this.vertical === 'l') {
      this.verticalClass = 'r-' + this.type + '-vertical--large';
    } else {
      throw new Error('Invalid argument for vertical. Was ['+ this.vertical + ']. Allowed: ["none", "s", "m", "l"].');
    }
    if (this.horizontal === 'none') {
      this.horizontalClass = '';
    } else if (this.horizontal === 's') {
      this.horizontalClass = 'r-' + this.type + '-horizontal--small';
    } else if (this.horizontal === 'm') {
      this.horizontalClass = 'r-' + this.type + '-horizontal--medium';
    } else if (this.horizontal === 'l') {
      this.horizontalClass = 'r-' + this.type + '-horizontal--large';
    } else {
      throw new Error('Invalid argument for horizontal. Was ['+ this.vertical + ']. Allowed: ["none", "s", "m", "l"].');
    }
  }
}
import Backend from 'backend';
import {inject} from 'aurelia-framework';

@inject(Backend)
export class SpecialPriceCalculator {
  backend;
  products;

  calculatedPrice;

  constructor(backend) {
    this.backend = backend;
    this.backend.CrateCatalogQueryHandler_handle()
      .then(result => {
        this.products = result.items
      })
  }

  calculateSumIncVat(productId, valueOfGoods) {
    // Products may not have loaded yet.
    if (!this.products) {
      return;
    }
    let crateProduct = this._getSingleCrateProduct(productId);
    return crateProduct.priceIncVat + Math.max(valueOfGoods, crateProduct.minPriceIncVat) + crateProduct.packingFeeIncVat;
  }


  calculateMinPriceDiffIncVat(productId, valueOfGoods) {
    // Products may not have loaded yet.
    if (!this.products) {
      return;
    }
    let minPriceIncVat = this.products.filter(p => p.productId === productId)[0].minPriceIncVat;
    return this.diffToMinPrice = Math.max(0, minPriceIncVat - valueOfGoods);
  }

  _getSingleCrateProduct(productId) {
    return this.products.filter(p => p.productId === productId)[0];
  }
}

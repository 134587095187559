import {inject, bindable, bindingMode, customElement, TaskQueue} from "aurelia-framework";

@inject(Element, TaskQueue)
@customElement('my-page-line-action-buttons')
export class MyPageLineActionButtons {
  element;
  taskQueue;

  @bindable() line;
  @bindable() subscription;

  constructor(element, taskQueue) {
    this.element = element;
    this.taskQueue = taskQueue;
  }
}
import {inject} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import Backend from "../backend";
import {Configurations} from "devtag-aurelia-config-plugin";

@inject(Backend, Router, Configurations)
export class ChangePaymentMeansScreen {
  backend;
  router;

  selectedPaymentMeans;
  existingPaymentMeans;

  chosenProducts;

  constructor(backend, router, configurations) {
    this.backend = backend;
    this.router = router;
    this.qpEnableTestMode = configurations.getConfig('qpEnableTestMode') === true || configurations.getConfig('qpEnableTestMode') === 'true';
  }

  activate(params) {
    this.subscriptionId = params.subscriptionId;

    // Process quickpay response.
    // We get params from bindable field because components don't have activate methods.
    switch (params.state) {
      case 'payment_cancelled':
        console.log('payment_cancelled');
        break;
      case 'payment_confirmed':
        this.backend.VerifyPaymentMeansQueryHandler_handle(params.paymentMeansId)
          .then(result => {
            if (result.ok === true) {
              this.backend.ChangePaymentMeansCommandHandler_handle(this.subscriptionId, params.paymentMeansId)
                .then(() => this.router.navigateToRoute("my-page"));
            } else {
              throw new Error("Got redirected back from qp with continue_url, but payment means could not be verified by backend.");
            }
          })
        break;
    }
    return this.refresh();
  }

  refresh() {
    return this.backend.ExistingPaymentMeansQueryHandler_handle()
      .then(result => this.existingPaymentMeans = result.paymentMeans);
  }

  createNewPaymentMeans() {
    let continuePath = this.router.generate('change-payment-means', { subscriptionId: this.subscriptionId, state: 'payment_confirmed', paymentMeansId: '$PAYMENT_MEANS_ID'});
    let cancelPath = this.router.generate('change-payment-means', { subscriptionId: this.subscriptionId, state: 'payment_cancelled'});
    let continueUrl = new URL(continuePath, window.location);
    let cancelUrl = new URL(cancelPath, window.location);
    return this.backend.CreateQuickpayLinkCommandHandler_handle("1", continueUrl.toString(), cancelUrl.toString())
      .then(result => {
        window.location = result.url;
      })
  }

  chooseExistingCard(paymentMeans) {
    this.selectedPaymentMeans = paymentMeans;
  }

  removeExistingCard(paymentMeans) {
    return this.backend.CancelPaymentMeansCommandHandler_handle({paymentMeansId: paymentMeans.paymentMeansId})
      .then(result => this.refresh());
  }

  useChosenPaymentMeans() {
    this.backend.ChangePaymentMeansCommandHandler_handle(this.subscriptionId, this.selectedPaymentMeans.paymentMeansId)
      .then(() => {
        this.router.navigateToRoute('my-page', {});
      });
  }

  useFakeTestPaymentMeans() {
    this.backend.CreateFakePaymentMeansCommandHandler_handle()
      .then(result => {
        this.backend.ChangePaymentMeansCommandHandler_handle(this.subscriptionId, result.paymentMeansId)
          .then(() => this.router.navigateToRoute('my-page', {}));
      });
  }

  back() {
    this.router.navigateToRoute('my-page', {});
  }
}

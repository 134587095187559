import {inject} from 'aurelia-framework';
import Backend from "../backend";
import {redirectToActiveSeoKey} from "../util/redirect-to-active-seo-key";

@inject(Backend)
export class NewsItemScreen {
  backend;

  constructor(backend) {
    this.backend = backend;
  }

  canActivate(params, routeConfig) {
    return this.backend.NewsItemQueryHandler_handle({newsItemIdOrSeoKey: params.newsItemIdOrSeoKey, limit: 1})
        .then(result => {
          this.newsItem = result.items[0];

          let structuredData = {
            "context": "http://schema.org/",
            "@type": "BlogPosting",
            "headline": this.newsItem.name,
            "datePublished": this.newsItem.publishDateTime.substring(0, 10),
            "dateModified": this.newsItem.publishDateTime.substring(0, 10),
            "image": this.newsItem.imageLargeUrl,
            "author": {
              "@type": "organization",
              "name": "Raago"
            },
            "publisher": {
              "@type": "Organization",
              "name": "Raago"
            }
          };
          this.newsItemStructuredDataJson = JSON.stringify(structuredData);

          // Title
          routeConfig.navModel.setTitle(this.newsItem.name + ' | ' + routeConfig.title);

          return redirectToActiveSeoKey(params, routeConfig, 'newsItemIdOrSeoKey', this.newsItem.seoKey);
        });
  }
}

import Backend from 'backend';
import {inject} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {UserInfoProvider} from "../util/user-info-provider";
import {RMatomo} from "../util/r-matomo";

@inject(Backend, Router, UserInfoProvider)
export class ChangeCrateProductScreen {
  backend;
  router;

  subscriptionId;
  subscriptionProductId;
  userInfoProvider;

  constructor(backend, router, userInfoProvider) {
    this.backend = backend;
    this.router = router;
    this.userInfoProvider = userInfoProvider;
  }

  activate(params) {
    this.subscriptionId = params.subscriptionId;
    this.subscriptionProductId = params.subscriptionProductId;
    this.existingCrateProductName = params.existingCrateProductName;
    return Promise.all([
      this.backend.CrateCatalogQueryHandler_handle()
        .then(result => this.products = result.items),
      this.backend.CmsFragmentPublicQueryHandler_handle({items: [
          {cmsFragmentKey: "change_crate_product__section1"},
        ]})
      .then(result => {
        this.cmsFragments = result;
      })
    ]);
  }

  purchase(product) {
    return this.backend.ChangeCrateProductCommandHandler_handle({subscriptionId: this.subscriptionId, lineId: this.subscriptionProductId, newProductId: product.productId})
      .then(() => RMatomo.trackGoalConversion(RMatomo.GOAL_ID__CHANGE_CRATE_TYPE))
      .then(() => this.userInfoProvider.reload())
      .then(() => {
        if (product.isCustomizable) {
          this.router.navigateToRoute("change-customizable-content", {subscriptionId: this.subscriptionId, subscriptionProductId: this.subscriptionProductId});
        } else {
          this.router.navigateToRoute("my-page");
        }
      })
  }
}

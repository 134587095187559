import {bindable, customElement} from "aurelia-framework";

@customElement('raago-container')
export class RaagoContainer {
  @bindable() marginLeft = true;
  @bindable() marginRight = true;

  @bindable() type = 'default'; // "default", "primary", "secondary"
  /** Specifically to stretch the background, not the content **/
  @bindable() stretch = 'none'; // "none", "container", "page"

  lOffset
  l;

  rowElement;
  colElement;
  bgElement;
  fgElement;

  typeToCssClassMap = new Map([
    [ 'default', [] ],
    [ 'primary', ['r-container--primary'] ],
    [ 'secondary', ['r-container--secondary'] ],
    [ 'secondary-light', ['r-container--secondary-light'] ],
    [ 'secondary-lighter', ['r-container--secondary-lighter'] ],
    [ 'blank', ['r-container--blank'] ],
  ]);

  bind() {
    this.marginLeft = this.marginLeft === true || this.marginLeft === 'true';
    this.marginRight = this.marginRight === true || this.marginRight === 'true';
    if (this.marginLeft) {
      this.lOffset = 1;
      if (this.marginRight) {
        this.l = 10
      } else {
        this.l = 11
      }
    } else {
      this.lOffset = 0;
      if (this.marginRight) {
        this.l = 11
      } else {
        this.l = 12
      }
    }
  }

  attached() {
    if (this.stretch === 'none') {
      this.addTypeClassesToElement(this.fgElement);
    } else if (this.stretch === 'container') {
      this.removePaddingIfNotMargin();
      this.addTypeClassesToElement(this.rowElement);
    } else if (this.stretch === 'page') {
      this.removePaddingIfNotMargin();
      this.addTypeClassesToElement(this.bgElement);
    } else {
      throw new Error('Invalid argument for stretch. Was ['+ this.stretch + ']. Allowed: ["none", "container", "page"].');
    }
  }

  removePaddingIfNotMargin() {
    if (!this.marginLeft) {
      this.colElement.style.paddingLeft = '0';
    }
    if (!this.marginRight) {
      this.colElement.style.paddingRight = '0';
    }
  }

  addTypeClassesToElement(element) {
    let classes = [];

    let typeClasses = this.typeToCssClassMap.get(this.type);
    if (typeClasses === undefined) {
      throw new Error('Invalid argument for type. Was ['+ this.type + ']. Allowed: [' + Array.from(this.typeToCssClassMap.keys()).map(key => "'" + key + "'") + '].');
    }
    classes.push(...typeClasses);

    // let styleClasses = this.styleToCssClassMap.get(this.style);
    // if (styleClasses) {
    //   classes.push(...styleClasses);
    // }

    element.classList.add(...classes);
  }
}
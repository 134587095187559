import {bindable, inject, customAttribute} from "aurelia-framework";

@customAttribute('raago-icon')
@inject(Element)
export class RaagoIconCustomAttribute {
  element;
  imgSrc;

  constructor(element) {
    this.element = element;
  }

  bind() {
    switch (this.value) {
      case 'book':
        this.element.src = "images/Rågo_ikoner_bok.svg";
        break;
      case 'circle':
        this.element.src = "images/circle.svg";
        break;
      case 'heart':
        this.element.src = "images/Rågo_ikoner_hjerte_bok.svg";
        break;
      case 'minus-black':
        this.element.src = "images/minus-black.svg";
        break;
      case 'minus-grey':
        this.element.src = "images/minus-grey.svg";
        break;
      case 'plus-black':
        this.element.src = "images/plus-black.svg";
        break;
      case 'plus-grey':
        this.element.src = "images/plus-grey.svg";
        break;
      case 'herb':
        this.element.src = "images/Rågo_ikoner_urter.svg";
        break;
      case 'bread':
        this.element.src = "images/Rågo_ikoner_brød_korn_gluten.svg";
        break;
      case 'carrot':
        this.element.src = "images/Rågo_ikoner_grønnsaker.svg";
        break;
      case 'bottle':
        this.element.src = "images/Rågo_ikoner_juice_saft_kombucha.svg";
        break;
      case 'coffee':
        this.element.src = "images/Rågo_ikoner_kaffe_te_sjokoalde.svg";
        break;
      case 'sausage':
        this.element.src = "images/Rågo_ikoner_kjøtt_egg_spekemat.svg";
        break;
      case 'cheese':
        this.element.src = "images/Rågo_ikoner_ost_honning_marmelade.svg";
        break;
      case 'fish':
        this.element.src = "images/Rågo_ikoner_sjømat.svg";
        break;
      default:
        break;
    }
  }
}

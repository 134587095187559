import Backend from 'backend';
import {ShoppingCart} from './shopping-cart';
import {bindable, customElement, inject} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {Configurations} from "devtag-aurelia-config-plugin";

@customElement('payment-component')
@inject(Element, Backend, ShoppingCart, Router, Configurations)
export class PaymentComponent {
  element;
  backend;
  shoppingCart;
  router;

  existingPaymentMeans;
  selectedPaymentMeans;

  showLoadingScreen = true;

  @bindable() params;
  constructor(element, backend, shoppingCart, router, configurations) {
    this.element = element;
    this.router = router;
    this.backend = backend;
    this.shoppingCart = shoppingCart;
    this.qpEnableTestMode = configurations.getConfig('qpEnableTestMode') === true || configurations.getConfig('qpEnableTestMode') === 'true';
  }

  attached() {
    // Process quickpay response.
    // We get params from bindable field because components don't have activate methods.
    switch (this.params.state) {
      case 'payment_cancelled':
        console.log('payment_cancelled');
        this.showLoadingScreen = false;
        break;
      case 'payment_confirmed':
        this.shoppingCart.clearPaymentLink();
        if (!this.shoppingCart.getPaymentMeansId()) {
          console.error('Got payment confirmed without payment means in cart.');
        }
        this.backend.VerifyPaymentMeansQueryHandler_handle(this.shoppingCart.getPaymentMeansId())
          .then(result => {
            if (result.ok === true) {
              this._checkout();
            } else {
              throw new Error("Got redirected back from qp with continue_url, but payment means could not be verified by backend.");
            }
          })
          .catch(error => {
            this.showLoadingScreen = false;
            throw error;
          })
        break;
      default:
        this.showLoadingScreen = false;
    }

    this.backend.ExistingPaymentMeansQueryHandler_handle()
      .then(result => this.existingPaymentMeans = result.paymentMeans);
  }

  /** Button event handler: Not choosing existing payment means, but wanting to create new. */
  createNewPaymentMeans() {
    // Redirect to quickpay
    let continuePath = this.router.generate('checkout-flow', {state: 'payment_confirmed'});
    let cancelPath = this.router.generate('checkout-flow', {state: 'payment_cancelled'});
    let continueUrl = new URL(continuePath, window.location);
    let cancelUrl = new URL(cancelPath, window.location);
    this.shoppingCart.findOrCreatePaymentLink(continueUrl.toString(), cancelUrl.toString())
      .then(url => window.location = url)
  }

  useFakeTestPaymentMeans() {
    this.shoppingCart.findOrCreateFakePaymentMeans()
      .then(() => this._checkout());
  }

  confirmPaymentWithExistingCard() {
    this.shoppingCart.useExistingPaymentMeansId(this.selectedPaymentMeans.paymentMeansId);
    this._checkout()
  }

  _checkout() {
    this.shoppingCart.checkout()
      .then(customerId =>
        this.element.dispatchEvent(new CustomEvent('checkout-complete', {
          detail: {
            customerId: customerId
          },
          bubbles: true
        }))
      );
  }

  chooseExistingCard(paymentMeans) {
    this.selectedPaymentMeans = paymentMeans;
  }

  back() {
    this.element.dispatchEvent(new CustomEvent('checkout-flow-back', {
      detail: {},
      bubbles: true
    }));
  }
}
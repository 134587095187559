import Backend from 'backend';
import {inject} from 'aurelia-framework';
import {UserInfoProvider} from "../../util/user-info-provider";

@inject(Backend, UserInfoProvider)
export class ShoppingCart {
  _info;
  listeners = [];

  backend;
  userInfoProvider;

  constructor(backend, userInfoProvider) {
    this.backend = backend;
    this.userInfoProvider = userInfoProvider;
  }

  load() {
    this._loadFromLocalStorage();
    if (!this._info) {
      this._info = new ShoppingCartInfo();
      this._persistToLocalStorage();
      this._publishInfo();
    }
    return this._validate()
      .catch(error => {
        if (error instanceof ShoppingCartValidationError) {
          console.warn("Clearing shopping cart due to error: [" + error.message + "].");
          this.clearCart();
        } else {
          throw error;
        }
      });
  }

  addListener(listener) {
    this.listeners.push(listener);
    listener(this._info);
  }

  removeListener(listener) {
    this.listeners = this.listeners.filter(l => l !== listener);
  }

  clearCart() {
    this._info = new ShoppingCartInfo();
    this._persistToLocalStorage();
    this._publishInfo();
  }

  getInfo() {
    if (this._info === undefined || this._info === null) {
      throw new Error('Shopping cart has not been initialized.');
    }
    let deepClone = JSON.parse(JSON.stringify(this._info));
    return deepClone;
  }

  saveStep(step) {
    if (this._info.step !== step) {
      this._info.step = step;
      this._persistToLocalStorage();
    }
  }

  setCrateProductId(crateProductId) {
    return this.getCrateCatalog()
      .then(result => {
        // Find product
        let crateProduct = result.items.filter(item => item.productId === crateProductId)[0];

        if (crateProduct === undefined) {
          throw new Error(`Crate product not found with id: ${crateProductId}`);
        }

        // Set product
        this.setCrateProduct(
          crateProductId,
          crateProduct.name,
          1,
          false,
          [],
          crateProduct.isCustomizable);

        this._info.step = 'init';
      });
  }

  setCrateProduct(productId, name, quantity, oneTime, subItems, customizable) {
    this._info.crateProduct = {
      productId: productId,
      name: name,
      quantity: quantity,
      oneTime: oneTime,
      subItems: subItems || [],
      isCustomizable: customizable
    };

    if (customizable) {
      this._info.weekExtraProducts = [];
    } else {
      this._info.customizationProducts = [];
    }

    this._persistToLocalStorage();
    this._publishInfo();
  }

  getDefaultCrateProductId() {
    return this.getCrateCatalog()
               .then(result => {
                 let defaultCrateProduct = result.items.filter(item => item.isDefaultCrate)[0];
                 if (!defaultCrateProduct) {
                   throw new Error('No crate product found with isDefaultCrate=true');
                 }
                 return defaultCrateProduct.productId;
               });
  }

  setInterval(
    weekInterval,
    startWeek,
    weekDay,
    commentForPacking,
    commentForDriver) {
    this._info.weekInterval = weekInterval;
    this._info.startWeek = startWeek;
    this._info.weekDay = weekDay;
    this._info.commentForPacking = commentForPacking;
    this._info.commentForDriver = commentForDriver;
    this._persistToLocalStorage();
    this._publishInfo();
  }

  addCustomizationProduct(productId, quantity, oneTime) {
    if (quantity === 0) {
      throw new Error("Do not add customization products with quantity zero. ProductId, name was: " + productId);
    }
    this._info.customizationProducts.push({
      productId: productId,
      quantity: quantity,
      oneTime: oneTime
    })

    this._persistToLocalStorage();
    this._publishInfo();
  }

  /** We always clear both because if we're about to add week extra products then there shouldn't be any customization products and vice versa. */
  clearWeekExtraAndCustomizationProducts() {
    this._info.weekExtraProducts = [];
    this._info.customizationProducts = [];
  }

  atomic(f) {
    try {
      this.mutePersistAndPublish = true
      f(this);
    } finally {
      this.mutePersistAndPublish = false;
    }
    this._persistToLocalStorage();
    this._publishInfo();
  }

  addWeekExtraProducts(productId, quantity, oneTime) {
    if (quantity === 0) {
      throw new Error("Do not add week extra with quantity zero. ProductId, name was: " + productId);
    }
    this._info.weekExtraProducts.push({
      productId: productId,
      quantity: quantity,
      oneTime: oneTime,
      subItems: []
    })

    if (!this.mutePersistAndPublish) {
      this._persistToLocalStorage();
      this._publishInfo();
    }
  }

  addSubProduct(productId, quantity) {
    if (this._info.crateProduct.isCustomizable) {
      this.addCustomizationProduct(productId, quantity, false)
    } else {
      this.addWeekExtraProducts(productId, quantity, true)
    }
  }

  hasCrateProduct() {
    return Boolean(this._info.crateProduct.productId);
  }
  /**
   * Some info can be gathered early, and should be registered even if we don't have address, etc. yet. This should be enough to create customer,
   * which can also be done before we have full subscription info.
   */
  setCustomerInfo(
    firstName,
    lastName,
    phoneNumber,
    email,
    consent) {
    this._info.firstName = firstName;
    this._info.lastName = lastName;
    this._info.phoneNumber = phoneNumber;
    this._info.email = email;
    this._info.consent = consent;

    this._persistToLocalStorage();
    this._publishInfo();
  }

  setAddress(
    existingAddressSubscriptionId,
    firstName,
    lastName,
    streetAddress,
    postalCode,
    city,
    phoneNumber,
    email,
    commentForDriver,
    consent) {
    this._info.existingAddressSubscriptionId = existingAddressSubscriptionId;
    this._info.firstName = firstName;
    this._info.lastName = lastName;
    this._info.streetAddress = streetAddress;
    this._info.postalCode = postalCode;
    this._info.city = city;
    this._info.phoneNumber = phoneNumber;
    this._info.email = email;
    this._info.commentForDriver = commentForDriver;
    this._info.consent = consent;

    this._info.existingAddressSubscriptionId = null;

    this._persistToLocalStorage();
    this._publishInfo();
  }

  isCustomizable() {
    return this._info && this._info.crateProduct && this._info.crateProduct.isCustomizable;
  }

  // Ask backend to calculate the 'in effect' cart.
  dryRunCheckout() {
    return this.backend.CartCalculationQueryHandler_dryRun({
        items: this.getItems(),
        weekInterval: this._info.weekInterval,
        startWeek: this._info.startWeek,
    })
      .then(result => {
        this._info.lastDryRunTotalIncVat = result.totalIncVat;
        return result;
      });
  }

  deletePaymentLink() {
    this._info.paymentMeansId = null
    this._info.paymentLink = null
    this._persistToLocalStorage();
    this._publishInfo();
  }

  clearPaymentLink() {
    this._info.paymentLink = null;
  }

  findOrCreatePaymentLink(continueUrl, cancelUrl) {
    if (this._info.paymentLink) {
      return new Promise(resolve => resolve(this._info.paymentLink));
    }
    return this.findOrCreateCustomer('shopping-cart#findOrCreatePaymentLink')
      .then(customerId => {
        return this.backend.CreateQuickpayLinkCommandHandler_handle(this._info.lastDryRunTotalIncVat, continueUrl, cancelUrl)
          .then(result => {
            this._info.paymentLink = result.url;
            this._info.paymentMeansId = result.paymentMeansId;
            this._persistToLocalStorage();
            this._publishInfo();
            return result.url;
          });
      });
  }
  useExistingPaymentMeansId(paymentMeansId) {
    this._info.paymentMeansId = paymentMeansId;
  }

  findOrCreateFakePaymentMeans() {
    if (this._info.fakePaymentMeans) {
      return new Promise(resolve => resolve(this._info.fakePaymentMeans));
    }
    return this.findOrCreateCustomer('shopping-cart#findOrCreateFakePaymentMeans')
      .then(customerId => {
        return this.backend.CreateFakePaymentMeansCommandHandler_handle(customerId)
          .then(result => {
            // Set the fake payment means so we can know whether or not we already have a _fake_ payment means id.
            this._info.fakePaymentMeans = result.paymentMeansId

            // Set the regular payment means to be used when doing checkout
            this._info.paymentMeansId = result.paymentMeansId
          });
      });
  }

  findOrCreateCustomer(debugDescription) {
    return this.backend.FindOrCreateCustomerCommandHandler_handle({firstName: this._info.firstName, lastName: this._info.lastName, phoneNumber: this._info.phoneNumber, email: this._info.email, consent: this._info.consent, debugDescription: debugDescription})
  }

  checkout() {
    return this.findOrCreateCustomer('shopping-cart#checkout')
      .then(customerId => {
        return this.backend.CheckoutCommandHandler_handle({
            weekInterval: this._info.weekInterval,
            startWeek: this._info.startWeek,
            weekDay: this._info.weekDay,
            firstName: this._info.firstName,
            lastName: this._info.lastName,
            streetAddress: this._info.streetAddress,
            postalCode: this._info.postalCode,
            city: this._info.city,
            phoneNumber: this._info.phoneNumber,
            email: this._info.email,
            commentForPacking: this._info.commentForPacking,
            commentForDriver: this._info.commentForDriver,
            subscription: true,
            paymentMeansId: this._info.paymentMeansId,
            items: this.getItems()
          })
          .then(() => this.clearCart())
          .then(() => this.userInfoProvider.reload())
          .then(() => customerId)
      });
  }

  getPaymentMeansId() {
    return this._info.paymentMeansId;
  }

  getItems() {
    let items = [];

    // Add crate product, if chosen.
    // Notable case where it isn't: right after clear cart.
    if (this._info.crateProduct.productId) {
      items.push({
        productId: this._info.crateProduct.productId,
        quantity: this._info.crateProduct.quantity,
        oneTime: this._info.crateProduct.oneTime,
        subItems: this._info.customizationProducts.map(subItem => {
          return {
            productId: subItem.productId,
            quantity: subItem.quantity,
            oneTime: subItem.oneTime
          };
        })
      });
    }

    // Add week extra products
    let weekExtraItems = this._info.weekExtraProducts.map(product => {
      return {
        productId: product.productId,
        quantity: product.quantity,
        oneTime: product.oneTime,
        subItems: []
      };
    });
    items.push(...weekExtraItems);
    return items;
  }
  _persistToLocalStorage() {
    localStorage.removeItem("shopping_cart_flow");
    localStorage.setItem("shopping_cart_flow", JSON.stringify(this._info));
  }

  _loadFromLocalStorage() {
    // Load json from local browser storage
    let json = localStorage.getItem("shopping_cart_flow");

    // Deserialize
    let info = JSON.parse(json);

    // If there was an object and it is of the correct schema version, use it, ...
    if (info && info.schemaVersion === ShoppingCartInfo.newestSchemaVersion) {
      this._info = info;
      this._publishInfo(this._info);
    }
    // ... if not, clear out the local storage so we get a fresh state and don't get stuck with incompatible schemas or
    else {
      localStorage.removeItem("shopping_cart_flow");
    }
  }

  _validate() {
    // Validate existence of crate product
    let crateProductId = this._info.crateProduct.productId;
    let crateProductValidated = crateProductId === undefined
      ? Promise.resolve()
      : this.getCrateCatalog()
                    .then(result => {
                      let products = result.items

                      let matchingProduct = products.some(p => p.productId === crateProductId);
                      if (!matchingProduct) {
                        throw new ShoppingCartValidationError(`Crate product not found: [${crateProductId}]`);
                      }
                    });

    // Validate existence of sub products
    let containsSubProducts = this._info.weekExtraProducts.length > 0 || this._info.customizationProducts.length > 0;
    let subProductsValidated = !containsSubProducts
      ? Promise.resolve()
      : this.backend.SubProductCategorizedQueryHandler_handle({})
                  .then(result => {
                    let products = result.products

                    // Validate existence of week extra products
                    this._info.weekExtraProducts.forEach(weekExtraProduct => {
                      let matchingProduct = products.some(p => p.productId === weekExtraProduct.productId);
                      if (!matchingProduct) {
                        throw new ShoppingCartValidationError(`Week extra product not found: ${weekExtraProduct.productId}`);
                      }
                    });

                    // Validate existence of week customizable sub products
                    this._info.customizationProducts.forEach(customizableProduct => {
                      let matchingProduct = products.some(p => p.productId === customizableProduct.productId);
                      if (!matchingProduct) {
                        throw new ShoppingCartValidationError(`Customizable product not found: ${customizableProduct.productId}].`);
                      }
                    });
                  });
    return Promise.all([
      crateProductValidated,
      subProductsValidated
    ]);
  }

  crateCatalogCache;
  getCrateCatalog() {
    if (this.crateCatalogCache) {
      return Promise.resolve(this.crateCatalogCache);
    } else {
      return this.backend.CrateCatalogQueryHandler_handle()
        .then(result => {
          this.crateCatalogCache = result;
          return result;
        });
    }
  }

  _setAndPublishInfo(info) {
    this._info = info;
    this._publishInfo();
  }

  _publishInfo() {
    this.listeners.forEach(listener => listener(this._info));
  }
}

class ShoppingCartInfo {
  static newestSchemaVersion = '2';

  schemaVersion;

  constructor() {
    this.schemaVersion = ShoppingCartInfo.newestSchemaVersion;
  }

  step;

  crateProduct = {};
  weekExtraProducts = [];
  customizationProducts = [];

  commentForPacking;
  commentForDriver;

  weekInterval = 'EVERY_WEEK';
  startWeek;
  weekDay;

  firstName;
  lastName;

  streetAddress;
  postalCode;
  city;
  phoneNumber;
  email;
  consent;

  lastDryRunTotalIncVat;

  paymentMeansId;
  paymentLink;

  fakePaymentMeans;
}

class ShoppingCartValidationError extends Error {
  constructor(message) {
    super(message);
    this.name = this.constructor.name;
  }
}

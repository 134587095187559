import {bindable, inject, customAttribute, customElement} from "aurelia-framework";
import {EventAggregator} from "aurelia-event-aggregator";
import {I18N} from "aurelia-i18n";
import {Router} from "aurelia-router";
import {UserInfoProvider} from "./util/user-info-provider";

@customElement('raago-app-error')
@inject(Element, EventAggregator, I18N, Router, UserInfoProvider)
export class RaagoAppErrorComponent {
  element;
  eventAggregator;
  i18N;
  router;
  userInfoProvider;

  modal;
  e;

  constructor(element, eventAggregator, i18N, router, userInfoProvider) {
    this.element = element;
    this.eventAggregator = eventAggregator;
    this.i18N = i18N;
    this.router = router;
    this.userInfoProvider = userInfoProvider;
  }

  bind() {
    // Dismiss on navigation
    this.eventAggregator.subscribe('router:navigation:success', e => {
      if (this.modal && this.modal.isOpen) {
        this.clickDismiss();
      }
    });

    RaagoAppErrorComponent.txsdfg = e => {
      this.e = e;
      if (this.e.exceptionName === 'NoCrateSubscriptionLineException') {
        this.message = this.i18N.tr('domain.errors.NoCrateSubscriptionLineException');
      } else if (this.e.exceptionName === 'BackofficeOnlyProductException') {
        let productName = this.e.exception.productName;
        this.message = this.i18N.tr('domain.errors.BackofficeOnlyProductException',
          {
            replace: {
              productName: productName
            },
            interpolation: {
              escapeValue: false
            }
          });
      } else if (this.e.exceptionName === 'NoEveryTimeCrateSubscriptionLineException') {
        this.extracted();
      } else {
        this.message = this.i18N.tr('domain.errors.unknown');
      }
      this.modal.open();
    };
  }

  extracted() {
    let postfix;
    if (this.router.currentInstruction.config.name !== 'crates-and-bundles') {
      let linkToCratesAndPackages = this.router.generate('crates-and-bundles', {subscriptionId: this.userInfoProvider.getInfo().subscriptionId});
      postfix = this.i18N.tr(
        'domain.errors.NoEveryTimeCrateSubscriptionLineException.navigationTip',
        {
          replace: {
            linkToCratesAndPackages: linkToCratesAndPackages
          },
          interpolation: {
            escapeValue: false
          }
        });

    } else {
      postfix = '';
    }

    this.message = this.i18N.tr(
      'domain.errors.NoEveryTimeCrateSubscriptionLineException.main',
      {
        replace: {
          postfix: postfix
        },
        interpolation: {
          escapeValue: false
        }
      });
  }

  clickDismiss() {
    this.modal.close();
    this.e = null;
  }

  static txsdfg;

  static handleError(aurelia, e) {
    // Decide to handle or not
    let shouldHandle = [
      'BackofficeOnlyProductException',
      'NoCrateSubscriptionLineException',
      'NoEveryTimeCrateSubscriptionLineException'
    ].includes(e.exceptionName);

    // If so,
    if (shouldHandle) {
      // Publish event
      // This is necessary to transport the error to the view model, since this is a static method with no reference to the vm instance.
      RaagoAppErrorComponent.txsdfg(e);

      // return true means we'll handle the error
      return true;

    } else {
      // return false means we don't handle the error
      return false;
    }
  }
}

import numeral from 'numeral';

export class NumeralTwoDecimalsValueConverter {
  minusSign = '−';
  hyphenMinusSign = '-';

  options1 = { minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping: false};
  numberFormatWithoutGrouping = new Intl.NumberFormat('no-NB', this.options1);

  options2 = { minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping: true};
  numberFormatWithGrouping = new Intl.NumberFormat('no-NB', this.options2);

  toView(value, useGrouping) {
    let valueAsNumber = Number(value);

    // Is it REALLY a number?
    if (Number.isNaN(valueAsNumber)) {
      return value;
    }

    // Format based on locale
    let formattedValue = this.getNumberFormat(useGrouping).format(valueAsNumber);

    // Replace minus with hyphen-minus because excel and calc don't interpret pasted data correctly
    let replacedMinusSign = formattedValue.replace(this.minusSign, this.hyphenMinusSign);

    // For development: replace with us-en decimal separator
    // replacedMinusSign = replacedMinusSign.replace(',', '.');

    return replacedMinusSign;
  }

  getNumberFormat(useGrouping) {
    return useGrouping === 'noGrouping' ? this.numberFormatWithoutGrouping : this.numberFormatWithGrouping;
  }

  fromView(value) {
    if (!numeral.validate(value)) {
        return value;
    }
    let viewModelValue = numeral(value).value();
    return viewModelValue;
  }
}

import {customElement, inject} from "aurelia-framework";
import {RouterEvent} from "aurelia-router";
import {EventAggregator} from "aurelia-event-aggregator";

@customElement('bonzer-organization-semantics-component')
@inject(EventAggregator)
export class BonzerOrganizationSemanticsComponent {
  ea;

  constructor(ea) {
    this.ea = ea;
  }

  bind() {
    this.ea.subscribe(RouterEvent.Complete, (event) => {
      // Create
      let mainSchema = createSchema(window.location);

      // Attach to DOM as <script> tag
      let scriptElementId = 'organization-ldjson';
      let existingScriptElement = document.getElementById(scriptElementId);
      if (existingScriptElement) {
        existingScriptElement.innerHTML = JSON.stringify(mainSchema);
      } else {
        let head = document.getElementsByTagName('head');
        let script = document.createElement('script');
        script.type = "application/ld+json";
        script.id = scriptElementId
        script.innerHTML = JSON.stringify(mainSchema);
        head[0].appendChild(script);
      }
    });
  }
}

function createSchema(location) {
  var jsonLdData = generateBreadcrumbData(location);
  var mainSchema = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "webpage",
        "@id": location.href + '#webpage',
        "url": location.href,
        "breadcrumb": jsonLdData,
        "isPartOf": {
          "@type": "website",
          "@id": location.origin,
          "about": {
            "@id": "https://raago.no//#organization"
          },
          "publisher": {
            "@id": "https://raago.no//#organization"
          }
        }
      },
      {
        "@type": "Organization",
        "name": "Raago",
        "alternateName": "Rågo",
        "url": location.origin,
        "vatID": "NO913630653",
        "logo": "https://raago.no/d625e895f2ad3bf9200b.svg",
        "@id": "https://raago.no//#organization",
        "contactPoint": [
          {
            "@type": "ContactPoint",
            "hoursAvailable": "08:30-16:00",
            "contactType": "customer service",
            "name": "Contact us",
            "telephone": "+4795414999"
          }
        ],
        "telephone": ["+4795414999"],
        "image": "https://raago.no/blob/?blobId=f518eabd-967d-4dc6-9ef0-cefa89c41b4b&expiry=&sig" +
          "nature=JaS7dad0bN7%2BUX8ZIuWxqTHAVrtORzgSGf0PHr7cUISw4CNmnhPOACIN3OceNgRmfwtPcS" +
          "w3ypv4RMZTPbMf%2BX%2FiIFxZQMhXTiJzxE2ZODMXnubgv0JpmaHaeJvIDg%2BK22VKCtY2a94ABxg" +
          "9%2BEsA7jVU05Y2XuSYrn9%2FQXkeJrNxI3zf4utt%2BdDCAqGvL7brTGvELeJzjTyR0FoOm8RQ%2BO" +
          "pHV1nJd9usxXD1BwxhfrG1he2qWd0yA%2B8Nx%2BrIOmeD5Owb9sFCMaHsXO9UsP3F2HCBjISk4LfaG" +
          "hOz%2F92Q%2BDNHXGCSUwpGI4J0GLJ9G%2FwoQrhsSPJvIyOhxN8EIp4NFw%3D%3D",
        "sameAs": [
          "https://www.facebook.com/raago.no",
          "https://www.instagram.com/raago.no"]
      }
    ]
  };
  return mainSchema;
}

function generateBreadcrumbData(location) {
  var url = location.href;
  var domain = location.origin;
  var path = url.replace(domain, ''); // Remove the domain part from the URL
  var slugs = path.split('/').filter(function (slug) {
    return slug !== '';
  });
  var breadcrumbList = [];
  var breadcrumbData = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": []
  };
  var homeItem = {
    "@type": "ListItem",
    "position": 1,
    "name": "Home",
    "item": domain
  };
  breadcrumbList.push(homeItem);
  for (var i = 0; i < slugs.length; i++) {
    var slug = slugs[i];
    var position = i + 2;
    var itemUrl = domain;
    for (var j = 0; j <= i; j++) {
      itemUrl += '/' + slugs[j];
    }
    var breadcrumbItem = {
      "@type": "ListItem",
      "position": position,
      "name": capitalize(slug.replace(/-/g, ' ')),
      "item": itemUrl + '/'
    };
    breadcrumbList.push(breadcrumbItem);
  }
  breadcrumbData.itemListElement = breadcrumbList;
  return breadcrumbData;
}

function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

import {inject, observable} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import Backend from "../backend";
import {SpecialPriceCalculator} from "./checkout-flow/specialPriceCalculator";
import {RMatomo} from "../util/r-matomo";

@inject(Backend, Router, SpecialPriceCalculator)
export class ChangeCustomizableContentScreen {
  backend;
  router;

  @observable({changeHandler: 'valueOfGoodsChanged'})
  valueOfGoods;
  chosenProducts = [];

  calculatedPrice;
  initValues = {};
  specialPriceCalculator;

  constructor(backend, router, specialPriceCalculator) {
    this.backend = backend;
    this.router = router;
    this.specialPriceCalculator = specialPriceCalculator;
  }
  attached() {
    return this.backend.CmsFragmentPublicQueryHandler_handle({items: [
          {cmsFragmentKey: "change_customizable_content__section1"}
        ]})
      .then(result => {
        this.cmsFragments = result;
      });
  }

  activate(params) {
    return this.backend.MyPageQueryHandler_handle({})
      .then(result => {
        // Params
        this.subscriptionId = params.subscriptionId;
        this.subscriptionProductId = params.subscriptionProductId;

        // Get subscription
        let subscription = result.subscriptions
          .filter(subscription => subscription.subscriptionId === params.subscriptionId)
          [0];
        if (!subscription) {
          throw new Error('Subscription not found with id: ' + params.subscriptionId);
        }

        // Get line
        let subscriptionLine = subscription
          .lines
          .filter(line => line.subscriptionProductId === params.subscriptionProductId)
          [0];
        if (!subscriptionLine) {
          throw new Error('SubscriptionLine not found with id: ' + params.subscriptionProductId);
        }

        // Load initial values
        this.subscriptionCrateProductId = subscriptionLine.productId;
        subscriptionLine
          .subLines
          .forEach(subLine => {
            this.initValues[subLine.productId] = {
                quantity: subLine.quantity
              };
          });
      });
  }

  purchase() {
    let items = this.chosenProducts.map(product => ({productId: product.productId, quantity: product.quantity}));
    return this.backend.ChangeCustomizableContentCommandHandler_handle({subscriptionId: this.subscriptionId, lineId: this.subscriptionProductId, subLines: items})
      .then(() => RMatomo.trackGoalConversion(RMatomo.GOAL_ID__CUSTOMIZABLE_CONTENT))
      .then(() => this.router.navigateToRoute('my-page'))
  }

  valueOfGoodsChanged() {
    // Min price
    this.calculatedPrice = this.specialPriceCalculator.calculateSumIncVat(this.subscriptionCrateProductId, this.valueOfGoods);
    this.diffToMinPrice = this.specialPriceCalculator.calculateMinPriceDiffIncVat(this.subscriptionCrateProductId, this.valueOfGoods);
  }
}

import {inject} from 'aurelia-framework';
import Backend from "../backend";
import {redirectToActiveSeoKey} from "../util/redirect-to-active-seo-key";
import {MetaTags} from "../util/meta-tags";

@inject(Backend)
export class RecipeScreen {
  backend;

  constructor(backend) {
    this.backend = backend;
  }

  canActivate(params, routeConfig) {
    return this.backend.RecipeQueryHandler_handle({recipeIdOrSeoKey: params.recipeIdOrSeoKey})
        .then(result => {
          this.recipe = result.items[0];

          // SEO: Meta tags
          MetaTags.setMetaTags_rememberToCallCleanOnUnbind(this.recipe.descriptionMetaTag);

          // SEO: structured data
          let structuredData = {
            "@context": "https://schema.org/",
            "@type": "Recipe",
            "author": {
              "@type":"organization",
              "name":"Raago"
            },
            "datePublished": (this.recipe.publishDateTime || this.recipe.createdDateTime).substring(0, 10),
            "description": this.recipe.name,
            "image": this.recipe.imageLargeUrl,
            "recipeIngredient": this.recipe.description1
              // Only one type of BR
              .replaceAll("<br/>", "<br>")
              .replaceAll("<br />", "<br>")
              // Remove line breaks
              .replaceAll("\r", "")
              .replaceAll("\n", "")
              // Remove other html that is common in this field.
              .replaceAll("<p>", "")
              .replaceAll("</p>", "")
              // Split lines
              .split("<br>")
              // Trim
              .map(s => s.trim())
              // Ignore empty lines
              .filter(item => item !== ''),
            "name": this.recipe.name,
            "recipeInstructions": this.recipe.description2
              .replaceAll("\n", "")
              .replaceAll("<br/>", "")
              .replaceAll("<br>", "")
              .replaceAll("<p>", "")
              .replaceAll("</p>", "\n\n")
          }
          this.recipeStructuredDataJson = JSON.stringify(structuredData);


          // SEO: Title
          routeConfig.navModel.setTitle(this.recipe.name + ' | ' + routeConfig.title);

          // SEO: handle seokey
          return redirectToActiveSeoKey(params, routeConfig, 'recipeIdOrSeoKey', this.recipe.seoKey);
        });
  }

  unbind() {
    MetaTags.cleanMetaTags();
  }
}

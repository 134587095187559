import {inject, bindable, customElement} from 'aurelia-framework';
import Backend from "../../backend";

@customElement('sub-product-item-component')
@inject(Element, Backend)
export class SubProductItemComponent {
  element;
  backend;
  shoppingCart;
  router;
  i18N;

  @bindable product;
  @bindable imgClasses = '';

  productForModal;

  @bindable showEveryTime = false;
  @bindable showQuantity = true;
  @bindable showBuyButton = false;
  @bindable showFeaturedBanner = true;

  constructor(element, backend) {
    this.element = element;
    this.backend = backend;
  }

  everyTimeChanged() {
    this.element.dispatchEvent(new CustomEvent('every-time-changed', {
      detail: {},
      bubbles: true
    }));
  }

  quantityChanged(event) {
    // The old event has the same name as the one we're about to raise, so it will bubble up and hit our parents' listeners.
    // We do not want duplicates, so the old event needs to be stopped.
    event.stopPropagation()
    this.element.dispatchEvent(new CustomEvent('quantity-changed', {
      detail: {},
      bubbles: true
    }));
  }

  buy() {
    this.element.dispatchEvent(new CustomEvent('buy', {
      detail: { product: this.product},
      bubbles: true
    }));
  }

  showProductDetails(product) {
    this.productForModal = null;
    this.backend.ProductDetailsQueryHandler_handle({productIdOrSeoKey: product.productId})
      .then(result => this.productForModal = result)
      .then(result => this.modal.open());
  }
}

import Backend from 'backend';
import {ShoppingCart} from './shopping-cart';
import {inject, customElement} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {IdentityProvider} from 'devtag-aurelia-auth-plugin';
import {AuthenticationService} from "devtag-aurelia-auth-plugin";
import {Configurations} from "devtag-aurelia-config-plugin";
import {ValidationController, ValidationRules} from "aurelia-validation";
import {I18N} from "aurelia-i18n";

@customElement('login-component')
@inject(Element, Backend, ShoppingCart, Router, IdentityProvider, AuthenticationService, Configurations, ValidationController, I18N)
export class LoginComponent {
  backend;
  shoppingCart;
  router;
  identityProvider;
  authenticationService;

  emailLogin;// = "dfg@dfg.no";

  firstName;// = "Torbjørn";
  lastName;// = "Søiland";
  email;// = "dfg@dfg.no";
  phoneNumber;
  consent;

  loadingInProgress = true;
  configurations;
  validationController;
  i18n;

  constructor(element, backend, shoppingCart, router, identityProvider, authenticationService, configurations, validationController, i18N) {
    this.element = element;
    this.router = router;
    this.backend = backend;
    this.shoppingCart = shoppingCart;
    this.identityProvider = identityProvider;
    this.authenticationService = authenticationService;
    this.configurations = configurations;
    this.validationController = validationController;
    this.i18n = i18N;

    // Create password reset url
    let encodedNextUrl = encodeURIComponent(configurations.getConfig('authAppBaseUrl') + "/#/checkout-flow");
    this.passwordResetUrl = configurations.getConfig('authAuthorityUrl') + "resetpasswordtoken?next=" + encodedNextUrl;
  }

  attached() {
    let info = this.shoppingCart.getInfo();
    this.firstName = info.firstName;
    this.lastName = info.lastName;
    this.email = info.email;
    this.phoneNumber = info.phoneNumber;
    this.consent = info.consent;

    if(this.identityProvider.isCurrentlyLoggedIn()) {
      return this.element.dispatchEvent(new CustomEvent('login', {
        detail: {},
        bubbles: true
      }));
    } else {
      this.loadingInProgress = false;
    }
  }

  bind() {
    ValidationRules
      .ensure('firstName').displayName(this.i18n.tr('screen.checkoutFlow.loginComponent.firstName'))
        .required()
        .minLength(2)
      .ensure('lastName').displayName(this.i18n.tr('screen.checkoutFlow.loginComponent.lastName'))
        .minLength(2)
        .required()
      .ensure('email').displayName(this.i18n.tr('screen.checkoutFlow.loginComponent.email'))
        .required()
        // random from internet
        .matches( /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
      .ensure('phoneNumber').displayName(this.i18n.tr('screen.checkoutFlow.loginComponent.phoneNumber'))
        .required()
        // Not validating phone numbers strictly because apparently we get foreign numbers (german f.ex.)
        // and formatting of numbers internationally seems like mine field
        // .matches(/^[0-9+()\- ]*$/) // First ever home cooked regex. Allow all numbers, plus, dash, paranthesis or space.

        // Gustav insisted parenthesis and dash were not necessary to allow.
        // .matches(/^(0047|\+47|47)?[2-9]\d{7}$/) // https://stackoverflow.com/questions/34001939/regular-expression-for-norwegian-numbers
        .matches(/^[0-9+ ]*$/) // Second ever home cooked regex. Allow all numbers, plus, or space.
        .withMessage(this.i18n.tr('screen.checkoutFlow.loginComponent.phoneNumber') + ' må bestå av tall, + eller mellomrom.')
      .ensure('termsOfService').displayName(this.i18n.tr('screen.checkoutFlow.loginComponent.termsOfService'))
        .required().withMessage(this.i18n.tr('screen.checkoutFlow.loginComponent.termsOfServiceMustBeAccepted'))
        .equals(true).withMessage(this.i18n.tr('screen.checkoutFlow.loginComponent.termsOfServiceMustBeAccepted'))
      .on(this);
  }

  login() {
    this.authenticationService.redirectToLogin('#/checkout-flow', {}, {
      showRegistration: false,
      prefillUsername: this.emailLogin,
      cancelRedirectUrl: this.configurations.getConfig('authAppBaseUrl') + "/#/checkout-flow"
    });
  }

  register() {
    this.validationController.validate()
      .then(result => {
        if (!result.valid) {
          return;
        }

        this.backend.UsernameAlreadyExistsQueryHandler_handle({username: this.email})
          .then(result => {
            if (result.alreadyExists) {
              this.validationController.addError(this.i18n.tr('screen.checkoutFlow.loginComponent.emailAlreadyInUse'), this, 'email')
            } else {
              this.shoppingCart.setCustomerInfo(
              this.firstName,
              this.lastName,
              this.phoneNumber,
              this.email,
              this.consent);

              this.authenticationService.redirectToLogin('#/checkout-flow', {}, {
                showRegistration: true,
                prefillUsername: this.email,
                prefillFirstName: this.firstName,
                prefillLastName: this.lastName,
                prefillEmail: this.email,
                hidePrefills: true,
                cancelRedirectUrl: this.configurations.getConfig('authAppBaseUrl') + "/#/checkout-flow"
              });
            }
          })
      });
  }

  next() {
  }

  back() {
    this.element.dispatchEvent(new CustomEvent('checkout-flow-back', {
      detail: {},
      bubbles: true
    }));
  }
}
import {inject, customElement, bindable} from "aurelia-framework";

@customElement('confirmation-modal-component')
@inject(Element)
export class ConfirmationModalComponent {
  element;

  @bindable
  hideCancelButton = false;

  modal;
  resolve;

  constructor(element) {
    this.element = element;
  }

  open() {
    this.modal.open();
    return new Promise(resolve => this.resolve = resolve);
  }

  clickOK() {
    this.modal.close();
    this.resolve(true);
  }

  clickCancel() {
    this.modal.close();
    this.resolve(false);
  }
}

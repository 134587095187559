import {inject} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import { activationStrategy } from 'aurelia-router';

@inject(Router)
export class ErrorScreen {
  router;

  constructor(router) {
    this.router = router;
  }

  activate(params) {
    // Use this to test the error handling in the route error handling
    // throw new Error('sdfg');

    // Get error data
    let errorData = sessionStorage.getItem(params.sessionStorageKey);

    // If there is any error data (if we are going backwards in history it might have been deleted by our primitive garbage collection.
    if (errorData) {
      // ... deserialize it
      let parse = JSON.parse(errorData);
      this.error = parse.error;
      this.navigationInstructionData = parse.navigationInstructionData;
    }
  }

  toggleShowDetails() {
    this.showDetails = !this.showDetails;
  }

  back() {
    window.history.back();
  }

  tryAgain() {
    return this.router.navigateToRoute(this.navigationInstructionData.routeName, {...this.navigationInstructionData.params, ...this.navigationInstructionData.queryParams});
  }

  // When we retry and it fails again we need to reload this screen with new data, not just keep the view model we have.
  determineActivationStrategy() {
    return activationStrategy.replace;
  }
}
import {bindable, customElement} from "aurelia-framework";

@customElement('raago-img-section')
export class RaagoImgSection {
  @bindable() imgSrc;
  @bindable() imgAlt;
  @bindable() imgClass;
  @bindable() href; //  can also use like this: route-href="route: my-page; params.bind: {test: 'test2'}"
  @bindable() imgAlign = 'left'; // "left", "right"

  @bindable({changeHandler: 'enableVideoChanged'}) enableVideo = false; // true or 'true'
  @bindable() videoSrc;
  @bindable() videoAlt;
  @bindable() videoClass;
  /** Optional. Used to minimize content and layout shift. */
  @bindable() estimatedAspectRatio;

  enableVideoChanged() {
    this.enableVideo = this.enableVideo === true || this.enableVideo === 'true';
  }
}

import {inject} from 'aurelia-framework';
import Backend from "../backend";
import isMobile from "ismobilejs";
import {UserInfoProvider} from "../util/user-info-provider";
import {Router} from "aurelia-router";
import {ShoppingCart} from "./checkout-flow/shopping-cart";

@inject(Backend, Router, UserInfoProvider, ShoppingCart)
export class Go {
  showGrid = false
  router;
  backend;
  userInfoProvider;
  shoppingCart;

  iconKey = 'bread';
  crateChoiceModalComponentRef;

  constructor(backend, router, userInfoProvider, shoppingCart) {
    this.router = router;
    this.backend = backend;
    this.userInfoProvider = userInfoProvider;
    this.shoppingCart = shoppingCart;
    this.isMobile = isMobile().phone;
  }

  activate(params) {
    this.hasActiveSubscription = Boolean(this.userInfoProvider.getInfo().subscriptionId);

    return Promise.all([
      this.shoppingCart.load()
        .then(() => {
          this.shoppingCartHasCrateProduct = this.shoppingCart.hasCrateProduct();
        }),
      this.backend.CrateCatalogQueryHandler_handle()
        .then(result => this.products = result.items),
      this.backend.CmsFragmentPublicQueryHandler_handle({items: [
            {cmsFragmentKey: "home__section1_image", imgWidth: 1920}, // used for mobile only. See usage in markup
            {cmsFragmentKey: "home__section1_html"},
            {cmsFragmentKey: "home__section2_html"},
            {cmsFragmentKey: "home__section3_1_html"},
            {cmsFragmentKey: "home__section3_2_html"},
            {cmsFragmentKey: "home__section3_3_html"},
            {cmsFragmentKey: "home__section3_1_image"},
            {cmsFragmentKey: "home__section3_2_image"},
            {cmsFragmentKey: "home__section3_3_image"},
            {cmsFragmentKey: "home__section4_html"},
            {cmsFragmentKey: "home__section5_html"},
            {cmsFragmentKey: "home__section5_image", imgWidth: 750},
            {cmsFragmentKey: "home__section6_html"},
            {cmsFragmentKey: "home__section6_image", imgWidth: 750},
            {cmsFragmentKey: "home__section7_html"},
            {cmsFragmentKey: "home__section8_image", imgWidth: 750},
            {cmsFragmentKey: "home__section8_html"},
            {cmsFragmentKey: "home__section9_html"},
          ]})
        .then(result => {
          this.cmsFragments = result;
        }),
      this.backend.NewsItemQueryHandler_handle({limit: 1})
        .then(result => this.newsItem = result.items[0]),
    ]);
  }

  featuredProductCheckedOut(eventDetails) {
    if (this.hasActiveSubscription) {
      // If already active subscription, do instant checkout which will in turn redirect to my page
      this.router.navigateToRoute('instant-checkout-route', {checkoutProductId: eventDetails.product.productId});
    } else if (this.shoppingCartHasCrateProduct) {
      // If product already chosen, add to shopping cart and go to checkout-flow
      this.shoppingCart.addSubProduct(eventDetails.product.productId, 1);
      this.router.navigateToRoute('checkout-flow', {step: 'first'});
    } else {
      // Else, crate product needs to be chosen. Show modal.

      // Set quantity.
      // The featured product component returns which product was clicked, but does not have a concept of quantity, so we assume he wanted 1x.
      eventDetails.product.quantity = 1;

      // Show crate choice modal
      this.crateChoiceModalComponentRef.show([eventDetails.product])
    }
  }
}

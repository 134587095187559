import {I18N} from 'aurelia-i18n';
import {inject} from 'aurelia-framework';

@inject(I18N)
export class TranslateEnumValueConverter {
  i18N;

  constructor(i18N) {
    this.i18N = i18N;
  }

  toView(enumValues, enumType, key) {
    // Handle null, undefined, etc.
    if (!enumValues) {
      return enumValues;
    }
    if (Array.isArray(enumValues)) {
      enumValues.forEach(enumValue => enumValue[key] = this.i18N.tr(enumType + '.' + enumValue[key]))
      return enumValues;
    } else {
      return this.i18N.tr(enumType + '.' + enumValues)
    }
  }
}

export class WeekNumberValueConverter {
  toView(value, lowerCase) {
    if (!value) {
      return value;
    }
    let s = 'Uke ' + value.substr(6);
    if (lowerCase) {
      s = s.toLocaleLowerCase();
    }
    return s;
  }
}

import {inject} from 'aurelia-framework';
import Backend from "../backend";
import './gift-card-printable-screen.scss'
import {GiftCardPurchaseScreen} from "./gift-card-purchase-screen";

@inject(Backend)
export class GiftCardPrintableScreen {
  backend;

  constructor(backend) {
    this.backend = backend;
  }

  activate(params) {
    let decoded = GiftCardPurchaseScreen.decodeGiftCardFromUrl(params.giftCardEncoded);
    this.token = decoded.token;
    this.initialAmount = decoded.initialAmount;
    this.expiryDate = decoded.expiryDate;
    this.message = decoded.message;
  }

  attached() {
    // Open print dialog after 1s
    setTimeout(() => {
      window.print();
    }, 1500)
  }
}

import {inject} from 'aurelia-framework';
import Backend from "../backend";

@inject(Backend)
export class Home {
  showGrid = true
  backend;

  constructor(backend) {
    this.backend = backend;
  }

  activate(params) {
    return this.backend.CmsFragmentPublicQueryHandler_handle({items: [
        {cmsFragmentKey: params.cmsFragmentKey},
      ]})
      .then(result => {
        this.cmsFragment = result[params.cmsFragmentKey];
      });
  }
}
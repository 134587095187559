import {inject, bindable, bindingMode, customElement} from 'aurelia-framework';
import {ValidationController, ValidationRules} from "aurelia-validation";
import {I18N} from 'aurelia-i18n';
import numeral from 'numeral';

@customElement('address-form-component')
@inject(Element, ValidationController, I18N)
export class AddressFormComponent {
  element;
  validationController;

  @bindable({defaultBindingMode: bindingMode.twoWay}) address;
  i18n;

  constructor(element, validationController, i18n) {
    this.validationController = validationController;
    this.i18n = i18n;
  }

  attached() {

  }


  bind() {
    ValidationRules
      .ensure('name').displayName(this.i18n.tr('domain.subscription.name')).required()
      .ensure('streetAddress').displayName(this.i18n.tr('domain.subscription.streetAddress')).required()
      .ensure('postalCode').displayName(this.i18n.tr('domain.subscription.postalCode'))
      .required()
      .satisfies(value => numeral.validate(value, 'en'))
      .withMessage('Postnummer må være et tall')
      .minLength(4)
      .maxLength(4)

      .ensure('city').displayName(this.i18n.tr('domain.subscription.city'))
      .required()

      .ensure('phoneNumber').displayName(this.i18n.tr('domain.subscription.phoneNumber'))
      .required()

      // Not validating phonenumbers strictly because apparantly we get foreign numbers (german f.ex.)
      // and formatting of numbers internationally seems like mine field
      // .matches(/^[0-9+()\- ]*$/) // First ever home cooked regex. Allow all numbers, plus, dash, paranthesis or space.

      // Gustav insisted parenthesis and dash were not necessary to allow.
      // .matches(/^(0047|\+47|47)?[2-9]\d{7}$/) // https://stackoverflow.com/questions/34001939/regular-expression-for-norwegian-numbers
      .matches(/^[0-9+ ]*$/) // Second ever home cooked regex. Allow all numbers, plus, or space.
      .withMessage(this.i18n.tr('domain.subscription.phoneNumber') + ' må bestå av tall, + eller mellomrom.')


      .ensure('email').displayName(this.i18n.tr('domain.subscription.email'))
      .required()
      // random from internet
      .matches( /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)

      // .ensure('commentForDriver')
      //   .required()

      .on(this.address);
  }
}
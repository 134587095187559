import {inject} from 'aurelia-framework';
import Backend from "../backend";

@inject(Backend)
export class SupplierIndexScreen {
  backend;

  constructor(backend) {
    this.backend = backend;
  }

  activate() {
    return Promise.all([
      this.backend.SupplierIndexQueryHandler_handle({})
          .then(result => this.suppliers = result.suppliers),
      this.backend.CmsFragmentPublicQueryHandler_handle({items: [
            {cmsFragmentKey: "supplierIndex__section1"},
          ]})
        .then(result => {
          this.cmsFragments = result;
        }),
    ]);
  }
}
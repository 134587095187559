import Backend from 'backend';
import {ShoppingCart} from './shopping-cart';
import {IdentityProvider} from 'devtag-aurelia-auth-plugin';
import {customElement, inject} from 'aurelia-framework';
import {ValidationController} from "aurelia-validation";
import {Router} from 'aurelia-router';

@customElement('address-component')
@inject(Element, Backend, ShoppingCart, Router, IdentityProvider,
  // Same validation controller instance as the address-change-component
  ValidationController)
export class AddressComponent {
  backend;
  shoppingCart;
  router;
  identityProvider;

  existingAddresses;

  address = {};
  validationController;

  constructor(element, backend, shoppingCart, router, identityProvider, validationController) {
    this.element = element;
    this.router = router;
    this.backend = backend;
    this.shoppingCart = shoppingCart;
    this.identityProvider = identityProvider;
    this.validationController = validationController;
  }

  attached() {
    let info = this.shoppingCart.getInfo();
    this.address.firstName = info.firstName;
    this.address.lastName = info.lastName;
    this.address.streetAddress = info.streetAddress;
    this.address.postalCode = info.postalCode;
    this.address.city = info.city;
    this.address.phoneNumber = info.phoneNumber;
    this.address.email = info.email;
    this.address.commentForDriver = info.commentForDriver;
    this.address.consent = info.consent;

    // Prefill info from logged in user. (Don't do this if the field is already filled in.)
    if(this.identityProvider.isCurrentlyLoggedIn()) {
      if (!this.address.name) {
        this.address.name = this.identityProvider.getFirstName() + ' ' + this.identityProvider.getLastName();
      }
      if (!this.address.email) {
        this.address.email = this.identityProvider.getEmail();
      }
    }

    return Promise.all([
      this.backend.MyExistingAddressesQueryHandler_handle()
        .then(result => this.existingAddresses = result.existingAddresses),
      this.backend.CmsFragmentPublicQueryHandler_handle({items: [
          {cmsFragmentKey: "checkout_flow__address__section1"},
        ]})
        .then(result => {
          this.cmsFragments = result;
        })
    ]);
  }

  choseAddress(existingAddress) {
    this.address.firstName = existingAddress.firstName;
    this.address.lastName = existingAddress.lastName;
    this.address.streetAddress = existingAddress.streetAddress;
    this.address.postalCode = existingAddress.postalCode;
    this.address.city = existingAddress.city;
    this.address.phoneNumber = existingAddress.phoneNumber;
    this.address.email = existingAddress.email;
    this.address.commentForDriver = existingAddress.commentForDriver;
  }

  next() {
    this.validationController.validate()
      .then(result => {
        if (!result.valid) {
          return;
        }

        this.shoppingCart.setAddress(
          null,
          this.address.firstName,
          this.address.lastName,
          this.address.streetAddress,
          this.address.postalCode,
          this.address.city,
          this.address.phoneNumber,
          this.address.email,
          this.address.commentForDriver,
          this.address.consent);
        this.shoppingCart.findOrCreateCustomer('address-component')
          .then(customerId =>
            this.element.dispatchEvent(new CustomEvent('checkout-flow-next', {
              detail: {},
              bubbles: true
            }))
          );
      });
  }

  back() {
    this.element.dispatchEvent(new CustomEvent('checkout-flow-back', {
      detail: {},
      bubbles: true
    }));
  }
}
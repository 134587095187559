import Backend from 'backend';
import {inject} from 'aurelia-framework';
import {Redirect, Router} from 'aurelia-router';
import {UserInfoProvider} from "../util/user-info-provider";
import {RMatomo} from "../util/r-matomo";
import {ShoppingCart} from "./checkout-flow/shopping-cart";

@inject(Backend, Router, UserInfoProvider, ShoppingCart)
export class InstantCheckoutRoute {
  backend;
  router;
  userInfoProvider;
  shoppingCart;

  constructor(backend, router, userInfoProvider, shoppingCart) {
    this.backend = backend;
    this.router = router;
    this.userInfoProvider = userInfoProvider;
    this.shoppingCart = shoppingCart;
  }

  canActivate(params) {
    // NOTE: Only authenticated users will arrive here

    // If productId is not specified, do not fail, go instead to my-page
    if (!params.checkoutProductId) {
      return new Redirect('my-page');
    }

    // Does user have subscription?
    let subscriptionId = params.subscriptionId || this.userInfoProvider.getInfo().subscriptionId;
    if (subscriptionId) {
      // User DOES have subscription
      return this.backend.CheckoutSubProductCommandHandler_handle({
                      subscriptionId: subscriptionId,
                      items: [{
                        productId: params.checkoutProductId,
                        incrementQuantity: 1,
                        initialOneTimeValue: true
                      }]
                    })
        .then(() => {
          // Track page before we redirect because it will not be auto tracked by router:success
          RMatomo.trackPageView(window.location.toString(), 'Instant checkout');

          // Go to my-page
          return new Redirect('my-page');
        });
    } else {
      // User does NOT have subscription.
      // Instead, add instantCheckoutProduct to shopping cart.
      return this.shoppingCart.load()
        .then(async () => {
          // But, if shopping cart doesn't have crate, we'll need to add one.
          if (!this.shoppingCart.hasCrateProduct()) {
            // What is the default crate when none is specified?
            return this.shoppingCart.getDefaultCrateProductId()
              .then(defaultCrateProductId => {
                // Add the default crate
                this.shoppingCart.setCrateProductId(defaultCrateProductId);
              });
          }
        })
        .then(() => {
          // Add the instant checkout subproduct
          this.shoppingCart.addSubProduct(params.checkoutProductId, 1);

          // Redirect to checkout flow
          return new Redirect('checkout-flow?step=interval');
        });
    }
  }
}

import {inject} from 'aurelia-framework';

import {IdentityProvider} from 'devtag-aurelia-auth-plugin';
import {UserInfoProvider} from "./user-info-provider";

@inject(IdentityProvider, UserInfoProvider)
export class TitleChangingRouteFilterValueConverter {
  userInfoProvider;
  identityProvider;

  constructor(identityProvider, userInfoProvider) {
    this.identityProvider = identityProvider;
    this.userInfoProvider = userInfoProvider;
  }

  toView(routes) {
    let userInfo = this.userInfoProvider.getInfo();

    // Get routes
    let route_checkoutFlowCustomizable = this.getRoute(routes, 'checkout-flow-customizable');
    let route_changeCustomizable = this.getRoute(routes, 'change-customizable-content');
    let route_changeWeekExtra = this.getRoute(routes, 'week-extra');
    let route_cratesAndBundles = this.getRoute(routes, 'crates-and-bundles');
    let route_crateCatalog = this.getRoute(routes, 'crate-catalog');
    let route_changeCrateProduct = this.getRoute(routes, 'change-crate-product-single');

    // Ignore all these routes
    let hideRoutesByDefault = [
      route_checkoutFlowCustomizable,
      route_changeCustomizable,
      route_changeWeekExtra,
      route_cratesAndBundles,
      route_crateCatalog,
      route_changeCrateProduct,
    ];

    // Except these
    let routesToShow = [];
    if (userInfo.hasSubscription) {
      if (userInfo.isCustomizable) {
        routesToShow.push(route_changeCustomizable);
      } else {
        routesToShow.push(route_changeWeekExtra);
      }
      routesToShow.push(route_changeCrateProduct);
    } else {
      routesToShow.push(route_checkoutFlowCustomizable);
      routesToShow.push(route_crateCatalog);
    }

    return routes.filter(route => {
      let ignoreThisRouteByDefault = hideRoutesByDefault.includes(route);
      let explicitlyShowThisRoute = routesToShow.includes(route);
      let hide = ignoreThisRouteByDefault && !explicitlyShowThisRoute;
      return !hide;
    });
  }

  getRoute(routes, name) {
    return routes
      .filter(route => route.config.name === name)
      [0];
  }
}

import Backend from 'backend';
import {bindable, bindingMode, customElement, inject} from 'aurelia-framework';
import {ValidationController, ValidationRules} from "aurelia-validation";
import {I18N} from 'aurelia-i18n';

@customElement('gift-card-claim-component')
@inject(Backend, ValidationController, I18N)
export class GiftCardClaimComponent {
  backend;
  validationController;
  i18n;

  @bindable({defaultBindingMode: bindingMode.fromView})
  totalRemainingAmount

  token;

  constructor(backend, validationController, i18n) {
    this.backend = backend;
    this.validationController = validationController;
    this.i18n = i18n;
  }

  attached() {
    return this.refresh();
  }

  bind() {
    ValidationRules
      .ensure('token').displayName(this.i18n.tr('domain.giftCard.token')).required()
      .on(this);
  }

  refresh() {
    return Promise.all([
      this.backend.ClaimedGiftCardsQueryHandler_handle({})
        .then(result => {
          this.claimedGiftCards = result.items;
          this.totalRemainingAmount = result.totalRemainingAmount;
        }),
    ]);
  }

  submit() {
    return this.validationController.validate()
      .then(result => {
        // Check validation
        if (!result.valid) {
          return;
        }

        // Invoke command
        return this.backend.GiftCardClaimCommandHandler_handle({ giftCardToken: this.token })
          .then(giftCardId => {
            this.token = null;
            return this.refresh();
          })
          .catch(error => {
            if (error.exceptionName === 'GiftCardTokenNotFoundException') {
              this.validationController.addError(this.i18n.tr('screen.giftCardClaimComponent.tokenNotFound'), this, 'token')
            } else if (error.exceptionName === 'GiftCardAlreadyClaimedException') {
              this.validationController.addError(this.i18n.tr('screen.giftCardClaimComponent.alreadyClaimed'), this, 'token')
            } else if (error.exceptionName === 'GiftCardExpiredException') {
              this.validationController.addError(this.i18n.tr('screen.giftCardClaimComponent.giftCardExpired'), this, 'token')
            } else {
              throw error;
            }
          });
      });
  }
}

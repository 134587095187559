export class MetaTags {
  static setMetaTags_rememberToCallCleanOnUnbind(description) {
    if (description) MetaTags._ensureMetaTag('description', description);
  }

  static cleanMetaTags() {
    MetaTags._cleanMetaTag('description');
  }

  static _ensureMetaTag(name, content) {
    // Find existing
    let existingLinkElement = document.head.querySelector(`head meta[name="${name}"]`);

    // If existing => set content ...
    if (existingLinkElement) {
      existingLinkElement.content = content;
    }
    // ... if not exists => create and set content.
    else {
      let meta = document.createElement('meta');
      meta.name = name;
      meta.content = content;
      document.head.appendChild(meta);
    }
  }

  static _cleanMetaTag(name) {
    // Find existing
    let existingLinkElement = document.head.querySelector(`meta[name="${name}"]`);

    // If exists => remove
    if (existingLinkElement) {
      existingLinkElement.remove()
    }
  }
}

import {inject} from 'aurelia-framework';
import Backend from "./backend";

@inject(Backend)
export class Home {
  showGrid = false
  backend;

  constructor(backend) {
    this.backend = backend;
  }

  activate() {
  }
}
import {bindable, customElement, inject} from 'aurelia-framework';
import Backend from "../backend";

@customElement('payment-notifications-component')
@inject(Element, Backend)
export class PaymentNotificationsComponent {
  element;
  backend;

  @bindable() subscriptionId;

  constructor(element, backend) {
    this.element = element;
    this.backend = backend;
  }

  attached() {
    this.backend.PaymentNotificationsQueryHandler_handle({subscriptionId: this.subscriptionId})
      .then(result => {
        this.subscriptionItems = result.subscriptionItems;
        this.isMissingPaymentMeans = result.isMissingPaymentMeans;
      })
      .catch(error => {
        // Mute because my-page can work fine without this component

        // Log to backend
        this.backend.RecordFrontendLogCommandHandler_handle({logData: 'error while loading payment status in payment-notifications-component: ' + JSON.stringify(error)})
      });
  }
}

import {inject} from 'aurelia-framework';
import {DtToastService} from "devtag-aurelia-components-plugin";
import {I18N} from "aurelia-i18n";

@inject(DtToastService, I18N)
export class RToastErrorRenderer {
  static id = 0;
  toastService;
  i18n;

  constructor(toastService, i18n) {
    this.toastService = toastService;
    this.i18n = i18n;
  }

  render(message, details = null, stack = null) {
    let toastErrorMessage;
    if (details instanceof String || (details != null && details.toString)) {
      details = details.toString().replace(/(?:\r\n|\r|\n)/g, '<br>'); // Replace new line with HTML line breaks
      stack = stack == null ? null : stack.replace(/(?:\r\n|\r|\n)/g, '<br>'); // Replace new line with HTML line breaks
    }

    let toastId = `dt-error-toast-${RToastErrorRenderer.id++}`;
    toastErrorMessage = `
      <div id="${toastId}">
        <h5>${message}</h5>
        <a href="#" style="float: right;" onclick="
            // Prevent browser from navigating based on this link click
            event.preventDefault();
            
            // Remove this toast
            let thisToastContent = document.querySelector('#${toastId}'); 
            let thisToast = thisToastContent.parentElement;
            thisToast.remove();
          ">${this.i18n.tr('errorHandler.dismiss')}</a>
          
        ${details != null ? `
          <a href="#" style="float: left;" onclick="
              // Prevent browser from navigating based on this link click
              event.preventDefault();
              
              // Toggle the tech details of this toast
              let thisToast = document.querySelector('#${toastId} .error-details'); 
              thisToast.style.display = thisToast.style.display === 'none' ? 'inherit' : 'none';
            ">
            ${this.i18n.tr('errorHandler.showHideDetails')}
          </a>
        
          <div class="error-details" style="display: none; clear: both;">
            <h6>${this.i18n.tr('errorHandler.detailsHeader')}</h6>
            <div>${details}</div>
            <small>
              ${stack != null
                  ? '<br>' + stack
                  : ''}
            </small>
          </div>` 
          : ''}
      </div>
    `;

    this.toastService.show(toastErrorMessage, Infinity, 'toast--error');
  }
}

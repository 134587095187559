import {inject} from 'aurelia-framework';
import Backend from "../backend";

@inject(Backend)
export class Home {
  showGrid = true
  backend;

  constructor(backend) {
    this.backend = backend;
  }

  activate() {
    return this.backend.CmsFragmentPublicQueryHandler_handle({items: [
        {cmsFragmentKey: "about_us__section1_image", imgWidth: 1920},
        {cmsFragmentKey: "about_us__section2_header"},
        {cmsFragmentKey: "about_us__section2"},
        {cmsFragmentKey: "about_us__section3_image", imgWidth: 750},
        {cmsFragmentKey: "about_us__section3"},
        {cmsFragmentKey: "about_us__section4_image", imgWidth: 750},
        {cmsFragmentKey: "about_us__section4"},
        {cmsFragmentKey: "about_us__section5_image", imgWidth: 750},
        {cmsFragmentKey: "about_us__section5"},
        {cmsFragmentKey: "about_us__section6_image", imgWidth: 750},
        {cmsFragmentKey: "about_us__section6_header"},
        {cmsFragmentKey: "about_us__section6"},
        {cmsFragmentKey: "about_us__section7"},
      ]})
      .then(result => {
        this.cmsFragments = result;
      });
  }
}
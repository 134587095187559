import {inject} from 'aurelia-framework';

import {AuthenticationService} from "devtag-aurelia-auth-plugin";

@inject(AuthenticationService)
export class Logout {
  constructor(authenticationService) {
    authenticationService.redirectToLogOut();
  }
}

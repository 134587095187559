import Backend from 'backend';
import {ShoppingCart} from './shopping-cart';
import {inject} from 'aurelia-framework';
import {Router} from 'aurelia-router';

@inject(Backend, ShoppingCart, Router)
export class ReceiptScreen {
  backend;
  router;

  constructor(backend, shoppingCart, router) {
    this.router = router;
    this.backend = backend;
  }

  activate() {
    return this.backend.CmsFragmentPublicQueryHandler_handle({items: [
          {cmsFragmentKey: "checkout_flow__receipt__section1"},
        ]})
      .then(result => {
        this.cmsFragments = result;
      })
  }
}
import Backend from 'backend';
import {inject} from 'aurelia-framework';
import {Router} from 'aurelia-router';

@inject(Backend, Router)
export class ChangeIntervalScreen {
  backend;
  router;

  subscriptionId;
  weekIntervals;
  weekInterval;

  constructor(backend, router) {
    this.backend = backend;
    this.router = router;
  }

  activate(params) {
    this.subscriptionId = params.subscriptionId;
    return Promise.all([
        this.backend.MyPageQueryHandler_handle({})
          .then(result => {
            let subscription = result.subscriptions.filter(subscription => subscription.subscriptionId === params.subscriptionId)[0];
            this.weekInterval = subscription.weekInterval
          }),
        this.backend.WeekIntervalsForDropdownQueryHandler_handle({subscriptionId: this.subscriptionId})
          .then(result => this.weekIntervals = result.weekIntervals),
    ]);
  }

  submit() {
    return this.backend.ChangeSubscriptionIntervalCommandHandler_handle({subscriptionId: this.subscriptionId, weekInterval: this.weekInterval})
      .then(() => this.router.navigateToRoute("my-page"));
  }
}
